import { groupBy, orderBy } from 'lodash';
import type { IOutrightTournamentResult, IResult } from '@/interface/IResult';
import { SportType } from '@/interface/enum';

export function getGroupedResults(results: IResult[]) {
    const sorted = orderBy(results, result => getSortingMatchCode(result), 'asc');

    const grouped = Object.entries(groupBy(sorted, result => result.leagueId));

    const sortedLeagues = orderBy(grouped, ([_, results]) => getSortingMatchCode(results[0]), 'asc');

    return sortedLeagues
        .map(([leagueId, results]) => ({
            id: parseInt(leagueId),
            title: results[0].leagueName,
            results: orderBy(results, result => result.showTime, 'asc'),
        }));
}

export function getGroupedOutrightResults(results: IOutrightTournamentResult[]) {
    const groups = Object.entries(groupBy(results, result => result.id));

    const groupedData = groups.map(([id, results]) => ({
        tournamentId: parseInt(id),
        title: results[0].name,
        results: orderBy(results.flatMap(result => result.outrights), outright => outright.eventDate, 'asc'),
    }));

    return orderBy(groupedData, result => result.results[0].matchCode, 'asc');
}

/**
 * All soccer match codes are in the format of "\d\d\d\d-eLLLLYYMMDDxxxx" (e.g. 0027-e00012345678)
 * - LLLL: league code
 * - YYMMDD: match date
 * - xxxx: match code
 *
 * The digits before dash are for odds display priority, the code after dash is for result sorting.
 * For soccer, we sort the matches by the whole match code, and sort the leagues by the code after dash.
 * For other sports, the format of match can be different, so we simply sort them by the whole match code.
 */
function getSortingMatchCode(result: IResult) {
    if (result.sportType === SportType.Soccer && result.matchCode.includes('-')) {
        return result.matchCode.split('-')[1];
    }

    return result.matchCode;
}
