import orderBy from 'lodash/orderBy';
import type { BetBuilderMarketFragment, BetBuilderMatchFragment, BetBuilderSelection } from '@/core/betBuilder/betBuilderType';
import type { IBetBuilderMarket, IBetBuilderMatch, IBetBuilderOrderResponse, IBetBuilderTicketResponse, IBetSlipSelection, ISelection } from '@/interface/betBuilder';
import { BetBuilderDisplayType, BetBuilderSelectionStatus, BetBuilderStatType, BetBuilderTicketStatus, PlaceOrderStatus, PriceStyle, StatementDetailStatus } from '@/interface/enum';
import type { BetBuilderMarketSelectionResponse, BetBuilderOrderResponse, BetBuilderTicketResponse, PriceResponse } from '@/interface/pollux';

const marketOrder = [
    BetBuilderDisplayType.Handicap,
    BetBuilderDisplayType.OddEven,
    BetBuilderDisplayType.OverUnder,
    BetBuilderDisplayType.CorrectScore,
    BetBuilderDisplayType._1X2,
    BetBuilderDisplayType.TotalGoal,
    BetBuilderDisplayType.FH_Handicap,
    BetBuilderDisplayType.FH_OverUnder,
    BetBuilderDisplayType.FH_1X2,
    BetBuilderDisplayType.HTFT,
    BetBuilderDisplayType.MoneyLine,
    BetBuilderDisplayType.FH_OddEven,
    BetBuilderDisplayType.FGLG,
    BetBuilderDisplayType.FH_CorrectScore,
    BetBuilderDisplayType.DoubleChance,
    BetBuilderDisplayType.LiveScore,
    BetBuilderDisplayType.FH_LiveScore,
    BetBuilderDisplayType.Game,
    BetBuilderDisplayType.Unknown,
];

const statTypeOrder = [
    BetBuilderStatType.Unknown,
    BetBuilderStatType.FullTime,
    BetBuilderStatType.FirstHalf,
    BetBuilderStatType.SecondHalf,
    BetBuilderStatType.FullTimeCorners,
    BetBuilderStatType.FirstHalfCorners,
    BetBuilderStatType.SecondHalfCorners,
    BetBuilderStatType.FullTimeBookingPoints,
    BetBuilderStatType.FirstHalfBookingPoints,
    BetBuilderStatType.SecondHalfBookingPoints,
];

const orderMarkets = (markets: IBetBuilderMarket[]) => orderBy(
    markets,
    [
        market => (marketOrder.includes(market.displayType) ? marketOrder.indexOf(market.displayType) : Infinity),
        market => (statTypeOrder.includes(market.matchStatType.id) ? statTypeOrder.indexOf(market.matchStatType.id) : Infinity),
        market => market.point,
        market => market.matchMarketId,
    ],
);

export const orderGroupedMarkets = (groupedMarkets: ({ key: string, value: IBetBuilderMarket[] })[]) => orderBy(
    groupedMarkets,
    [
        ({ value: markets }) => (marketOrder.includes(markets[0].displayType) ? marketOrder.indexOf(markets[0].displayType) : Infinity),
        ({ value: markets }) => (statTypeOrder.includes(markets[0].matchStatType.id) ? statTypeOrder.indexOf(markets[0].matchStatType.id) : Infinity),
        ({ value: markets }) => markets[0].matchMarketId,
    ],
);

export function formatBetBuilderMatch(result: BetBuilderMatchFragment): IBetBuilderMatch {
    const formattedMarkets = orderMarkets(formatMarkets(result.markets));

    return {
        matchId: result.matchId,
        markets: formattedMarkets,
    };
}

function formatMarkets(markets: BetBuilderMarketFragment[]) {
    return markets.map<IBetBuilderMarket>(market => ({
        matchMarketId: market.matchMarketId,
        marketType: {
            id: market.marketType.id,
            name: market.marketType.name,
        },
        matchStatType: {
            id: market.matchStatType.id,
            name: market.matchStatType.name ?? '',
        },
        displayType: BetBuilderDisplayType.fromRogue(market.displayType),
        point: market.point,
        selections: orderBy(formatBetBuilderSelections(market.selections), [
            selection => selection.type.id,
            selection => selection.id,
        ]),
    }));
}

function formatBetBuilderSelections(selections: BetBuilderSelection[]) {
    return selections.map<ISelection>(selection => ({
        id: selection.id,
        type: {
            id: selection.selectionType.id,
            name: selection.selectionType.name,
        },
        status: BetBuilderSelectionStatus.fromRogue(selection.status),
    }));
}

export function formatBetBuilderTicket(response: BetBuilderTicketResponse): IBetBuilderTicketResponse {
    return {
        matchId: response.matchId,
        markets: response.markets.map(market => ({
            matchMarketId: market.matchMarketId,
            selections: market.selections.map<IBetSlipSelection>(formatBetSlipSelection),
        })),
        minBet: response.minBet ?? 0,
        maxBet: response.maxBet ?? 0,

        price: formatPrice(response.price),
        status: BetBuilderTicketStatus.fromPollux(response.status),
        uid: response.uid,

        liveHomeScore: response.liveHomeScore,
        liveAwayScore: response.liveAwayScore,

        selectedSelections: response.selectedMarketSelections.map(selection => ({
            id: selection.selectionId,
            status: BetBuilderSelectionStatus.fromPollux(selection.status),
        })),

        balance: response.balance ?? null,
    };
}

function formatBetSlipSelection(selection: BetBuilderMarketSelectionResponse): IBetSlipSelection {
    return ({
        id: selection.selectionId,
        status: BetBuilderSelectionStatus.fromPollux(selection.status),
        isCompatible: selection.isCompatible,
        price: selection.price ? {
            value: selection.price.value,
            style: PriceStyle.fromPollux(selection.price.style),
        } : null,
    });
}

function formatPrice(priceResponse: PriceResponse) {
    return {
        value: priceResponse.value,
        style: PriceStyle.fromPollux(priceResponse.style),
    };
}

export function formatBetBuilderOrder(order: BetBuilderOrderResponse): IBetBuilderOrderResponse {
    return {
        isPlaceBetSuccess: order.isPlaceBetSuccess,
        transId: order.transId,
        selectionIds: order.selectionIds ?? [],
        placeOrderStatus: PlaceOrderStatus.fromPollux(order.placeOrderStatus),
        orderStatus: StatementDetailStatus.fromPollux(order.orderStatus),
        price: formatPrice(order.price),
        isAccountClosed: order.isAccountClosed,
    };
}
