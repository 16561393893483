import { apmBase } from '@elastic/apm-rum';
import { ApmVuePlugin } from '@elastic/apm-rum-vue';
import { createLazyIcon, registerIconClassName } from '@sbo/icons-vue';
import { MoneyPlugin } from '@sports-utils/money';
import { createPinia, storeToRefs } from 'pinia';
import type { App, Component, Plugin } from 'vue';
import { createApp } from 'vue';
import { routerFactory } from '../../router'; // if use @ alias, will order below apollo and will cause transform error ¯\_(ツ)_/¯
import { getApmConfig } from '@/core/lib/apm';
import { installSportsGtm } from '@/core/lib/gtm';
import { i18nInstance } from '@/core/lib/i18n';
import { setupApollo } from '@/core/oddsApi/apollo';
import { DebugDirective } from '@/directives/debug';
import { ResizeDirective } from '@/directives/resize';
import { ToolTipDirective } from '@/directives/tooltip';
import { VisibleDirective } from '@/directives/visible';
import { useCustomerStore } from '@/store/customerStore';
import { useToggleStore } from '@/store/toggleStore';

export interface SetupOption {
    isCreateNew?: boolean;
    plugins?: Plugin[];
}

const defaultSetupOptions: Partial<SetupOption> = {
    isCreateNew: false,
    plugins: [],
};

export const vueDevTools: Plugin = {
    install(app: App) {
        if (!__ENV_PROD__ && window.__VUE_DEVTOOLS_GLOBAL_HOOK__) {
            window.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = app;
        }
    },
};

export async function setupApp(component: Component, domId: string, _options: SetupOption = {}) {
    const app = createApp(component);
    const options = {
        ...defaultSetupOptions,
        ..._options,
    };
    app.directive('visible', VisibleDirective);
    app.directive('tooltip', ToolTipDirective);
    app.directive('resize', ResizeDirective);
    app.directive('debug', DebugDirective);

    app.use(i18nInstance);
    app.use(createPinia());
    app.use(vueDevTools);
    app.use(MoneyPlugin);
    app.use(createLazyIcon());
    app.use(registerIconClassName('sports'));

    installSportsGtm(app);

    const { initCustomerInfo } = useCustomerStore();
    await initCustomerInfo();

    const { initToggle } = useToggleStore();
    const { siteStyle } = useCustomerStore();
    await initToggle(siteStyle);
    const { isIdleMonitorEnabled } = storeToRefs(useToggleStore());
    setupApollo(app);
    const router = routerFactory(siteStyle);
    app.use(router);

    if (__ENABLE_APM__) {
        app.use(ApmVuePlugin, {
            config: getApmConfig(),
            router,
        });

        const MIN_IDLE_DURATION = 2;
        const MAX_IDLE_DURATION = 60;
        const PRECISE_IDLE_LOG_DURATION = 10;
        const IMPRECISE_IDLE_LOG_INTERVAL = 5;

        if (isIdleMonitorEnabled.value) {
            let idleTimer: number;
            let startTime: Date | null;

            const clearIdleTimer = () => {
                if (idleTimer) {
                    clearInterval(idleTimer);
                    idleTimer = 0;
                    startTime = null;
                }
            };

            const incrementIdleTime = () => {
                if (!startTime) return;

                const currentTime = new Date();

                const durationInMs = currentTime.getTime() - startTime.getTime();
                const durationInMinutes = Math.floor(durationInMs / 60_000);

                if (durationInMinutes >= MIN_IDLE_DURATION && durationInMinutes <= MAX_IDLE_DURATION) {
                    if (durationInMinutes <= PRECISE_IDLE_LOG_DURATION || durationInMinutes % IMPRECISE_IDLE_LOG_INTERVAL === 0) {
                        const error = new Error(`User was idle for ${durationInMinutes} minute(s)`);
                        apmBase.captureError(error);
                    }
                }

                if (durationInMinutes > MAX_IDLE_DURATION) {
                    clearIdleTimer();
                }
            };

            const startIdleTimer = () => {
                startTime = new Date();
                idleTimer = window.setInterval(incrementIdleTime, 60_000);
            };

            const handleVisibilityChange = () => {
                if (document.hidden) {
                    startIdleTimer();
                } else {
                    clearIdleTimer();
                }
            };

            document.addEventListener('visibilitychange', handleVisibilityChange);
        }
    }

    if (options.plugins && options.plugins.length > 0) {
        options.plugins.forEach(plugin => app.use(plugin));
    }

    if (options.isCreateNew) {
        const ele = document.createElement('div');
        ele.id = domId;
        document.body.appendChild(ele);
    }

    app.mount(`#${domId}`);

    return app;
}
