import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useState } from '@/composable/useState';
import type { ISportListIcon } from '@/interface/ISport';
import { AsiaBetPageType, AsiaRoutePage, EventDateType, MarketPage, PromotionZone, SiteStyle, SportType } from '@/interface/enum';
import { useCustomerStore } from '@/store/customerStore';
import { useToggleStore } from '@/store/toggleStore';

interface IIcon {
    name: string;
    size: 'sm' | 'xs' | 'md' | 'lg' | 'xl' | 'xxl' | number;
}

export interface IPromotionConfig {
    /**
     * The i18n key of title of the promotion zone
     */
    name: string;
    /**
     * The route that should be navigated to when clicking the promotion zone
     */
    route: string;
    routePage: AsiaRoutePage | null;
    enabled: boolean;
    /**
     * The icon that should be displayed in the promotion zone title
     */
    icon: IIcon | null;
    hotLink: {
        /**
         * The icon that should be displayed in the hot link
         */
        icon: ISportListIcon | null;
    } | null;
    /**
     * The sport type that should be selected by default when entering the promotion zone
     * If null, the sport type will not be changed
    */
    defaultSport: SportType | null;
    /**
     * The league names filter for the promotion zone
     */
    filteredLeagueNames: string[] | null;
    /**
     * The league ids filter for the promotion zone
     */
    filteredLeagueIds: number[] | null;
    betPage: AsiaBetPageType | null;
    gtmLabel: string;
    excludeMarketPages: MarketPage[];
}

/*
    Only used by useAsiaSportMarketInfoStore
 */
export const _usePromotionZone = () => {
    const [promotionZone, setPromotionZone] = useState(PromotionZone.None);

    const { isEFootballEnabled } = storeToRefs(useCustomerStore());
    const {
        isEFootballZoneEnabled,
        isShowMultipleSportsHotLink,
        isShowSportCupHotLink,
        isShowSportCup2HotLink,
        multipleSportsLeagueNames,
        eFootballLeagueIds,
        sportsCupTournamentNames,
        sportsCup2TournamentNames,
    } = storeToRefs(useToggleStore(SiteStyle.Asia));

    const multipleSportsZoneEventDateOrder = [
        EventDateType.Today,
        EventDateType.Plus1,
        EventDateType.Plus2,
        EventDateType.Plus3,
        EventDateType.Plus4,
        EventDateType.Plus5,
        EventDateType.Plus6Over,
        EventDateType.All,
    ];

    const promotionZoneConfigs = computed<Record<PromotionZone, IPromotionConfig | null>>(() => ({
        [PromotionZone.None]: null,
        [PromotionZone.MultipleSports]: {
            name: `promotion_zone_${PromotionZone.MultipleSports}`,
            route: '/multiple-sports',
            routePage: AsiaRoutePage.MultipleSportsPage,
            enabled: isShowMultipleSportsHotLink.value,
            icon: { name: 'icon-sport-color-olympic', size: 'lg' },
            hotLink: {
                icon: { name: 'icon-sport-color-olympic', size: 'lg' },
            },
            defaultSport: SportType.Soccer,
            filteredLeagueNames: multipleSportsLeagueNames.value,
            filteredLeagueIds: [],
            betPage: AsiaBetPageType.MultipleSports,
            gtmLabel: 'Olympic',
            excludeMarketPages: [],
        },

        [PromotionZone.EFootball]: {
            name: `promotion_zone_${PromotionZone.EFootball}`,
            route: '/e-football',
            routePage: AsiaRoutePage.EFootballPage,
            enabled: isEFootballEnabled.value && isEFootballZoneEnabled.value,
            icon: { name: 'icon-system-e-football', size: 'lg' },
            hotLink: {
                icon: { name: 'icon-system-e-football', size: 'lg' },
            },
            defaultSport: SportType.Soccer,
            filteredLeagueNames: [],
            filteredLeagueIds: eFootballLeagueIds.value,
            betPage: AsiaBetPageType.EFootball,
            gtmLabel: 'eFootball',
            excludeMarketPages: [MarketPage.Outright],
        },

        [PromotionZone.SportsCup]: {
            name: `promotion_zone_${PromotionZone.SportsCup}`,
            route: '/sports-cup',
            routePage: AsiaRoutePage.SportsCupPage,
            enabled: isShowSportCupHotLink.value,
            icon: { name: 'icon-sport-trophy', size: 'lg' },
            hotLink: {
                icon: {
                    name: 'icon-sport-trophy',
                    size: 18,
                    style: {
                        margin: '-2px -1px',
                    },
                },
            },
            defaultSport: SportType.Soccer,
            filteredLeagueNames: sportsCupTournamentNames.value,
            filteredLeagueIds: [],
            betPage: AsiaBetPageType.SportsCup,
            gtmLabel: 'SportsCup',
            excludeMarketPages: [],
        },

        [PromotionZone.SportsCup2]: {
            name: `promotion_zone_${PromotionZone.SportsCup2}`,
            route: '/copa',
            routePage: AsiaRoutePage.SportsCup2Page,
            enabled: isShowSportCup2HotLink.value,
            icon: { name: 'icon-sport-color-copaCup', size: 'xl' },
            hotLink: {
                icon: {
                    name: 'icon-sport-color-copaCup',
                    size: 20,
                    style: {
                        margin: '-3px -2px',
                    },
                },
            },
            defaultSport: SportType.Soccer,
            filteredLeagueNames: sportsCup2TournamentNames.value,
            filteredLeagueIds: [],
            betPage: AsiaBetPageType.SportsCup,
            gtmLabel: 'CopaZone',
            excludeMarketPages: [],
        },
    }));

    const getPromotionZoneConfig = (promotionZone: PromotionZone) => promotionZoneConfigs.value[promotionZone] ?? null;
    const currentPromotionZoneConfig = computed(() => getPromotionZoneConfig(promotionZone.value));

    return {
        promotionZone,
        setPromotionZone,
        getPromotionZoneConfig,
        currentPromotionZoneConfig,
        multipleSportsZoneEventDateOrder,
    };
};
