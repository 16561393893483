import { apm } from '@elastic/apm-rum';
import { sleep } from '@sports-utils/timer';
import { tryOnScopeDispose, useThrottleFn, useTimeoutFn, whenever } from '@vueuse/core';
import type { CancelTokenSource } from 'axios';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import { storeToRefs } from 'pinia';
import type { Ref } from 'vue';
import { computed, watch, watchEffect } from 'vue';
import type { IAsiaBetSlipContext } from '@/components/betSlip/betSlipContext/BaseBetSlipContext';
import { useBaseBetSlipContext } from '@/components/betSlip/betSlipContext/BaseBetSlipContext';
import type {
    PlaceBetErrorHandler,
    PlaceSingleBetErrorRules,
} from '@/components/betSlip/betSlipContext/usePlaceBetErrorHandler';
import { usePlaceBetErrorHandler } from '@/components/betSlip/betSlipContext/usePlaceBetErrorHandler';
import { usePlatform } from '@/composable/usePlatform';
import { Api, isWaitingForOpenTicket, lastOpenTickets } from '@/core/lib/api';
import { i18n } from '@/core/lib/i18n';
import { isMainMarket } from '@/core/lib/oddsHelper';
import { getMaxPayout, getOpenTicketErrorMessage, getPlaceBetErrorMessage, getVoucherMaxPayout } from '@/core/lib/ticket';
import { isVoucherValidForOdds } from '@/core/lib/voucherHelper';
import { useOddsSubscriptionCallback } from '@/core/oddsApi/composable/useOddsSubscriptionCallback';
import { useTicketEvent } from '@/core/oddsApi/composable/useTicketEvent';
import { getOddsPresetFilterInputType } from '@/core/oddsApi/helpers';
import { EnumOddsCategoryType } from '@/core/oddsApi/oddsApiType';
import type { IBetSlip, ITicket } from '@/interface/IBetSlip';
import type { IEvent } from '@/interface/IEvent';
import { OpenTicketStatus, PlaceOrderStatus, PriceStyle, SiteStyle } from '@/interface/enum';
import { useBetSlipStore } from '@/store/betSlipStore';
import { useCustomerStore } from '@/store/customerStore';
import { useLayoutStore } from '@/store/layoutStore';
import { useToggleStore } from '@/store/toggleStore';

export const createSingleBetSlipContext = (singleBetSlip: Ref<IBetSlip>): IAsiaBetSlipContext => {
    const {
        removeSingleBetSlip,
        updateSingleBetSlip,
        setSingleLastStake,
    } = useBetSlipStore();

    const betSlipContext = useBaseBetSlipContext(singleBetSlip);
    const {
        stakeInput,
        totalOddsPrice,
        selectedVoucher,
        setSelectedVoucher,
        setVoucherEnabled,
        vouchers,
        setVouchers,
        setBetSlipMessage,
        setStakeMessage,
        cancelTicketMillisecond,
        toggleIsPlaceBetInProgress,
        emitPlaceBetSuccess,
        autoRefresh,
        isPlaceBetEnabled,
    } = betSlipContext;
    const { setStake } = stakeInput;

    const singleTicket = computed(() => singleBetSlip.value.tickets[0]);

    const { priceStyle } = storeToRefs(useCustomerStore());
    const { formatMoneyWithDecimal } = useCustomerStore();
    const maxPayout = computed(() => {
        const payout = selectedVoucher.value === null
            ? getMaxPayout(stakeInput.stake.value, totalOddsPrice.value, singleTicket.value.priceStyle)
            : getVoucherMaxPayout(selectedVoucher.value.stake, totalOddsPrice.value, singleTicket.value.priceStyle);
        return formatMoneyWithDecimal(payout);
    });

    const { isVoucherEnabled } = storeToRefs(useToggleStore(SiteStyle.Asia));
    const getVouchers = async () => {
        if (!isVoucherEnabled.value) return [];

        try {
            return await Api.getVouchers(
                singleTicket.value.event.sportType,
                singleTicket.value.event.league.id,
            );
        } catch {
            return [];
        }
    };

    let cancelToken: CancelTokenSource;
    const openTicket = async () => {
        try {
            if (cancelToken) cancelToken.cancel('invalid previous openTicket');
            cancelToken = axios.CancelToken.source();

            const [{ data: ticketResponse }, voucherResponse] = await Promise.all([
                Api.openSingleTicket(singleTicket.value, cancelToken.token),
                getVouchers(),
            ]);

            if (!ticketResponse.oddsInfo) return;

            const oddsData = ticketResponse.oddsInfo[singleTicket.value.key];
            if (!oddsData) return;

            if (oddsData.errorCode) {
                setBetSlipMessage(getOpenTicketErrorMessage(oddsData.errorCode));

                if (OpenTicketStatus.cancelTicketStatus.includes(oddsData.errorCode)) {
                    useTimeoutFn(() => cancelTicket(), cancelTicketMillisecond);
                }

                return;
            }

            const priceStyle = PriceStyle.fromPollux(oddsData.priceStyle);
            updateSingleBetSlip((betSlip) => {
                betSlip.uid = oddsData.uid ?? '';
                betSlip.minBet = ticketResponse.minBet;
                betSlip.maxBet = ticketResponse.maxBet;
                betSlip.balance = ticketResponse.balance ?? null;

                const ticket = betSlip.tickets[0];
                ticket.odds.betCondition = oddsData.betCondition ?? '';
                ticket.odds.point = oddsData.point;
                ticket.priceStyle = priceStyle;
                ticket.priceOption.price = oddsData.price;

                if (isMainMarket(ticket.odds)) {
                    ticket.event.homeTeam.liveScore = oddsData.liveHomeScore;
                    ticket.event.awayTeam.liveScore = oddsData.liveAwayScore;
                }

                const eventResult = ticket.event.eventResults.find(eventResult => eventResult.id === ticket.odds.eventResult.id);
                if (eventResult) {
                    eventResult.homeTeam.liveScore = oddsData.liveHomeScore;
                    eventResult.awayTeam.liveScore = oddsData.liveAwayScore;
                }

                return betSlip;
            });

            setVouchers(voucherResponse);
        } catch (error) {
            if (axios.isCancel(error)) {
                // do nothing
            } else {
                console.error(error);
                setBetSlipMessage(i18n.t('bet_slip_error_message_general_failure'));
                useTimeoutFn(() => cancelTicket(), cancelTicketMillisecond);
            }
        }
    };

    const isVoucherAvailable = computed(() => vouchers.value.length !== 0);
    const isVoucherValidForTicket = computed(() => isVoucherValidForOdds(singleTicket.value.priceOption.price, singleTicket.value.priceStyle));

    watchEffect(() => {
        if (!isVoucherValidForTicket.value) {
            setVoucherEnabled(false);
            setSelectedVoucher(null);
        }
    });

    const ticketContextChangeHandler: PlaceBetErrorHandler = (_placeOrderStatus: PlaceOrderStatus, _extraInfo: string) => {
        openTicket();
    };

    const invalidEventOrPriceHandler: PlaceBetErrorHandler = (placeOrderStatus: PlaceOrderStatus, _extraInfo: string) => {
        setBetSlipMessage(getPlaceBetErrorMessage(placeOrderStatus));
        useTimeoutFn(() => cancelTicket(), cancelTicketMillisecond);
    };

    const invalidVoucherHandler: PlaceBetErrorHandler = (placeOrderStatus: PlaceOrderStatus, _extraInfo: string) => {
        setBetSlipMessage(getPlaceBetErrorMessage(placeOrderStatus));
        openTicket();
    };

    const limitExceedErrorHandler: PlaceBetErrorHandler = (_placeOrderStatus: PlaceOrderStatus, extraInfo: string) => {
        const alternateStake = parseInt(extraInfo);
        if (!Number.isNaN(alternateStake)) {
            setBetSlipMessage(i18n.t('bet_slip_error_message_limit_exceed', { alternateStake }));
            setStake(alternateStake);
        } else {
            setBetSlipMessage(getPlaceBetErrorMessage(PlaceOrderStatus.LimitExceedTryLater));
        }
    };

    const { placeBetErrorRuleMap, defaultErrorHandler, errorMessageHandler } = usePlaceBetErrorHandler(betSlipContext);

    const placeSingleBetErrorRuleMap: Partial<Record<PlaceSingleBetErrorRules, PlaceBetErrorHandler>> = {
        ...placeBetErrorRuleMap,
        [PlaceOrderStatus.RetryOpenTicket]: ticketContextChangeHandler,
        [PlaceOrderStatus.PriceChange]: ticketContextChangeHandler,
        [PlaceOrderStatus.HdpPointChange]: ticketContextChangeHandler,
        [PlaceOrderStatus.ScoreChanged]: ticketContextChangeHandler,
        [PlaceOrderStatus.PriceSuspend]: invalidEventOrPriceHandler,
        [PlaceOrderStatus.InvalidPrice]: invalidEventOrPriceHandler,
        [PlaceOrderStatus.EventClosed]: invalidEventOrPriceHandler,
        [PlaceOrderStatus.LimitExceed]: limitExceedErrorHandler,
        [PlaceOrderStatus.LimitExceedTryLater]: errorMessageHandler,
        [PlaceOrderStatus.ThirdPartyTimeout]: errorMessageHandler,
        [PlaceOrderStatus.InvalidVoucher]: invalidVoucherHandler,
        [PlaceOrderStatus.InvalidPriceForVoucher]: invalidVoucherHandler,
    };

    const { refreshBalanceAfterAction } = usePlatform();

    const { singleLastStake, isShowLastStake } = storeToRefs(useBetSlipStore());
    const { placeBetDelayTime } = storeToRefs(useToggleStore(SiteStyle.Asia));

    const placeBet = async () => {
        try {
            toggleIsPlaceBetInProgress(true);

            const voucher = selectedVoucher.value;
            const hasVoucher = voucher !== null;
            const stake = hasVoucher ? voucher.stake : stakeInput.stake.value;
            const api = hasVoucher
                ? Api.placeAsiaFreeBet(singleBetSlip.value, stake, voucher.id)
                : Api.placeAsiaSingleBet(singleBetSlip.value, stake);
            const [{ data: response }] = await Promise.all([api, sleep(placeBetDelayTime.value * 1000)]);

            if (response.isPlaceBetSuccess) {
                const { toggleBetSlipExpanded } = useLayoutStore();
                toggleBetSlipExpanded(false);
                removeSingleBetSlip();
                emitPlaceBetSuccess();
                if (!hasVoucher && isShowLastStake.value) {
                    setSingleLastStake(stake);
                }
                refreshBalanceAfterAction();
                return;
            }
            const placeOrderStatus = PlaceOrderStatus.fromPollux(response.placeOrderStatus);
            const handler = placeSingleBetErrorRuleMap[placeOrderStatus as PlaceSingleBetErrorRules] ?? defaultErrorHandler;
            handler(placeOrderStatus, response.extraInfo);
        } catch (error) {
            console.error(error);
            errorMessageHandler(PlaceOrderStatus.GeneralFailure, '');
        } finally {
            toggleIsPlaceBetInProgress(false);
        }
    };

    if (isShowLastStake.value) {
        setStake(singleLastStake.value);
    }

    function cancelTicket() {
        removeSingleBetSlip();
    }

    function subscribeOdds(ticket: Ref<ITicket>) {
        const { priceStyle } = storeToRefs(useCustomerStore());
        const {
            onUpdated,
            onDeleted,
        } = useOddsSubscriptionCallback({
            eventId: ticket.value.event.id,
            oddsId: ticket.value.odds.id,
            presetFilter: getOddsPresetFilterInputType(ticket.value.odds.isLive),
            oddsCategory: EnumOddsCategoryType.All,
            priceStyle,
        });

        onUpdated(() => {
            if (!singleBetSlip.value.uid) return;

            openTicket();
        });

        onDeleted(() => {
            setBetSlipMessage(i18n.t('bet_slip_error_message_odds_closed'));
            useTimeoutFn(cancelTicket, cancelTicketMillisecond);
        });
    }

    function updateEvent(event: IEvent) {
        singleTicket.value.event = cloneDeep(event);
    }

    function updateWhenLangChanged(ticket: Ref<ITicket>) {
        const { loaded, event } = useTicketEvent(ticket.value.event.id, EnumOddsCategoryType.All);
        whenever(loaded, () => {
            if (event.value) {
                updateEvent(event.value);
            }
        });
    }

    autoRefresh(openTicket);

    watch([() => singleBetSlip.value.uid, () => singleTicket.value.key], () => {
        if (!singleBetSlip.value.uid) {
            openTicket();
            setStakeMessage('');
            setVoucherEnabled(false);
            if (isShowLastStake.value) {
                setStake(singleLastStake.value);
                return;
            }
            setStake(0);
        }
    }, { immediate: true });

    const openTicketThrottled = useThrottleFn(openTicket, 1000, true, false);

    watch(priceStyle, () => {
        openTicketThrottled();
    });

    type CustomError = Error & Record<string, string | boolean | number | Date>
    const logOpenTicketStuckError = (message: string, duration: number) => {
        console.error(message, duration, isWaitingForOpenTicket.value, [...lastOpenTickets.value]);
        const error: CustomError = {
            name: 'Open Ticket stuck',
            message,
            duration,
            lastOpenTickets: JSON.stringify(lastOpenTickets.value),
            isWaitingForOpenTicket: isWaitingForOpenTicket.value,
        };
        apm.captureError(error);
    };

    let startTime = new Date();
    watch([isPlaceBetEnabled, () => singleBetSlip.value.tickets], () => {
        if (!isPlaceBetEnabled.value) { // gray
            startTime = new Date();
        } else { // yellow
            const now = new Date();
            const duration = (now.getTime() - startTime.getTime());
            if (duration > 5000) {
                logOpenTicketStuckError(`Open Ticket stuck for more than ${Math.floor(duration / 5000) * 5}s!`, duration);
            }
        }
    }, { immediate: true });

    tryOnScopeDispose(() => {
        if (!isPlaceBetEnabled.value) { // gray
            const now = new Date();
            const duration = now.getTime() - startTime.getTime();
            if (duration > 5000) {
                logOpenTicketStuckError(`Component unmounted while gray for more than ${Math.floor(duration / 5000) * 5}s!`, duration);
            }
        }
    });

    return {
        ...betSlipContext,
        isVoucherAvailable,
        isVoucherValidForTicket,
        maxPayout,
        openTicket,
        cancelTicket,
        placeBet,
        subscribeOdds,
        updateWhenLangChanged,
    };
};
