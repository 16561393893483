/* eslint-disable max-classes-per-file */
import { sleep } from '@sports-utils/timer';
import { useBroadcastChannel, useTimeoutFn } from '@vueuse/core';
import { cashOutedDelay } from '@/components/myBets/betHelper';
import { usePlatform } from '@/composable/usePlatform';
import { Api } from '@/core/lib/api';
import type { ICashOutData } from '@/interface/ICashOut';
import { BroadcastChannelName, CashOutResult, CashOutStatus } from '@/interface/enum';

const errorMessageDuration = 3000;

export class CashOutService {
    public currentState: CashOutState;
    transactionId: number;
    result: CashOutResult;
    cashOutValue: number;
    confirmedCashOutValue: number;
    _isSupported: boolean;
    isMixParlay: boolean;

    constructor(cashOutData: ICashOutData) {
        if (cashOutData.cashOutValue > 0) {
            this.currentState = new CashOutAvailable(this);
        } else {
            this.currentState = new CashOutUnAvailable(this);
        }
        this.transactionId = cashOutData.transactionId;
        this.result = cashOutData.result;
        this.cashOutValue = cashOutData.cashOutValue;
        this._isSupported = cashOutData.isSupported;
        this.confirmedCashOutValue = cashOutData.cashOutValue;
        this.isMixParlay = cashOutData.isMixParlay;
    }

    public get status() {
        return this.currentState.status;
    }

    public updateCashOutData(cashOutData: ICashOutData) {
        this.transactionId = cashOutData.transactionId;
        this.result = cashOutData.result;
        this.cashOutValue = cashOutData.cashOutValue;
        this._isSupported = cashOutData.isSupported;
        this.isMixParlay = cashOutData.isMixParlay;
    }

    public get isSupported() {
        return [
            CashOutStatus.Available,
            CashOutStatus.UnAvailable,
            CashOutStatus.Confirm,
        ].includes(this.status) ? this._isSupported : true;
    }

    public getCashOutValue() {
        return this.status === CashOutStatus.Confirm
            ? this.confirmedCashOutValue
            : this.cashOutValue;
    }

    public cashOut() {
        this.currentState.cashOut();
        this.confirmedCashOutValue = this.cashOutValue;
    }

    public async confirmCashOut() {
        this.currentState.confirmCashOut();

        try {
            const cashOutApi = this.isMixParlay ? Api.cashOutMixParlayBet : Api.cashOutSingleBet;
            const { data } = await cashOutApi(this.transactionId, this.confirmedCashOutValue);

            const status = CashOutResult.fromPollux(data.status);

            if (status === CashOutResult.Success) {
                const { post } = useBroadcastChannel({ name: BroadcastChannelName.CashOut });
                const { refreshBalanceAfterAction } = usePlatform();

                this.currentState.goSuccess();
                post({ name: 'cashOutSuccess' });
                useTimeoutFn(() => refreshBalanceAfterAction(), cashOutedDelay);
                return;
            }

            if (status === CashOutResult.CashOutValueTooHigh) {
                this.currentState.goPriceError();
                return;
            }

            if (status === CashOutResult.SettlementInProgress) {
                this.currentState.goSettlementInProgressError();
                return;
            }

            if (status === CashOutResult.GeneralError) {
                this.currentState.goGeneralError();
                return;
            }

            this.currentState.goError();
        } catch (e) {
            console.error(e);
            this.currentState.goError();
        }
    }

    cancel() {
        this.currentState.cancel();
    }

    goAvailable() {
        this.currentState.goAvailable();
    }

    goUnAvailable() {
        this.currentState.goUnAvailable();
    }
}

abstract class CashOutState {
    context: CashOutService;
    abstract status: CashOutStatus;

    constructor(context: CashOutService) {
        this.context = context;
    }

    cashOut() {
        throw new Error('cashOut not implement');
    }

    confirmCashOut() {
        throw new Error('confirmCashOut not implement');
    }

    cancel() {
        throw new Error('cancel not implement');
    }

    goAvailable() {
        throw new Error('goAvailable not implement');
    }

    goUnAvailable() {
        throw new Error('goUnAvailable not implement');
    }

    goSuccess() {
        throw new Error('goSuccess not implement');
    }

    goError() {
        throw new Error('goError not implement');
    }

    goPriceError() {
        throw new Error('goPriceError not implement');
    }

    goSettlementInProgressError() {
        throw new Error('goSettlementInProgressError not implement');
    }

    goGeneralError() {
        throw new Error('goGeneralError not implement');
    }
}

class CashOutAvailable extends CashOutState {
    status = CashOutStatus.Available;

    cashOut() {
        this.context.currentState = new CashOutConfirm(this.context);
    }

    goUnAvailable() {
        this.context.currentState = new CashOutUnAvailable(this.context);
    }
}

class CashOutConfirm extends CashOutState {
    status = CashOutStatus.Confirm;

    confirmCashOut() {
        this.context.currentState = new CashOutProcessing(this.context);
    }

    cancel() {
        if (this.context.cashOutValue > 0) {
            this.context.currentState = new CashOutAvailable(this.context);
        } else {
            this.context.currentState = new CashOutUnAvailable(this.context);
        }
    }
}

class CashOutProcessing extends CashOutState {
    status = CashOutStatus.Processing;

    goSuccess() {
        this.context.currentState = new CashOutSuccess(this.context);
    }

    async goError() {
        this.context.currentState = new CashOutError(this.context);
    }

    async goPriceError() {
        this.context.currentState = new CashOutPriceError(this.context);
    }

    async goSettlementInProgressError() {
        this.context.currentState = new CashOutSettlementInProgressError(this.context);
    }

    async goGeneralError() {
        this.context.currentState = new CashOutGeneralError(this.context);
    }
}

class CashOutSuccess extends CashOutState {
    status = CashOutStatus.Success;
}

class CashOutError extends CashOutState {
    status = CashOutStatus.Error;

    constructor(context: CashOutService) {
        super(context);
        sleep(errorMessageDuration).then(() => {
            if (this.context.cashOutValue > 0) {
                this.goAvailable();
            } else {
                this.goUnAvailable();
            }
        });
    }

    goAvailable() {
        this.context.currentState = new CashOutAvailable(this.context);
    }

    goUnAvailable() {
        this.context.currentState = new CashOutUnAvailable(this.context);
    }
}

class CashOutPriceError extends CashOutError {
    status = CashOutStatus.PriceError;
}

class CashOutSettlementInProgressError extends CashOutError {
    status = CashOutStatus.SettlementInProgressError;
}

class CashOutGeneralError extends CashOutError {
    status = CashOutStatus.GeneralError;
}

class CashOutUnAvailable extends CashOutState {
    status = CashOutStatus.UnAvailable;

    goAvailable() {
        this.context.currentState = new CashOutAvailable(this.context);
    }
}
