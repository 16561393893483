<template>
    <Message
        v-gtm:@view
        v-gtm:label="message.gtm"
        v-bind:show="isShow"
        v-bind:message="message.text"
        v-bind:status="message.status"
        rounded
        noIcon
        textCenter
        v-bind:progressDuration="message.progressDuration"
        position="bottom"
    />
</template>

<script lang="ts" setup>
    import type { PropType } from 'vue';
    import { computed } from 'vue';
    import Message from '@/components/betSlip/Message.vue';
    import { i18n } from '@/core/lib/i18n';
    import type { ICashOutMessage } from '@/interface/ICashOut';
    import { CashOutStatus } from '@/interface/enum';

    const props = defineProps({
        cashOutStatus: {
            type: Number as PropType<CashOutStatus>,
            required: true,
        },
        isShow: {
            type: Boolean,
            required: true,
        },
        isMiniMyBet: {
            type: Boolean,
            required: true,
        },
    });

    const CashOutMessageMapping = computed<Partial<Record<CashOutStatus, ICashOutMessage>>>(() => ({
        [CashOutStatus.Success]: {
            status: 'success',
            text: props.isMiniMyBet ? i18n.t('mini_my_bet_cash_out_success_message') : i18n.t('my_bet_cash_out_success_message'),
            gtm: 'Msg_Successfully',
        },
        [CashOutStatus.Processing]: {
            status: 'info',
            text: i18n.t('cash_out_cashing'),
            progressDuration: 5000,
            gtm: 'Msg_PleaseWaitProcessed',
        },
        [CashOutStatus.PriceError]: {
            status: 'danger',
            text: i18n.t('cash_out_price_changed'),
            gtm: 'Msg_ValueChanged',
        },
        [CashOutStatus.SettlementInProgressError]: {
            status: 'danger',
            text: i18n.t('cash_out_settlement_in_progress_error'),
            gtm: 'Msg_SettlementInProgressError',
        },
        [CashOutStatus.Error]: {
            status: 'danger',
            text: i18n.t('cash_out_failed'),
            gtm: 'Msg_Issue',
        },
        [CashOutStatus.GeneralError]: {
            status: 'danger',
            text: i18n.t('cash_out_unavailable'),
            gtm: 'Msg_Issue',
        },
    }));
    const message = computed<ICashOutMessage>(() => CashOutMessageMapping.value[props.cashOutStatus] ?? { status: 'success', text: '', gtm: '' });
</script>
