import { defineStore } from 'pinia';
import { computed, watch } from 'vue';
import { useState } from '@/composable/useState';
import { Api } from '@/core/lib/api';
import { addDay, formatDate, getTodayWithGMT, subMonth } from '@/core/lib/date';
import { i18n } from '@/core/lib/i18n';
import { getGroupedOutrightResults, getGroupedResults } from '@/core/lib/resultGroupingHelper';
import { getLocalesSortingFn } from '@/core/lib/string';
import type { IOutrightResult, IOutrightTournamentResult, IResult } from '@/interface/IResult';
import { ResultsDateFilter, SportType } from '@/interface/enum';

export interface IDateRange {
    startDate: Date; // inclusive
    endDate: Date; // inclusive
}

export interface IOutrightResultTournament {
    id: number;
    name: string;
}

export interface IOutrightResults {
    tournament: IOutrightResultTournament;
    outrights: IOutrightResult[];
}

export const useResultsStore = defineStore('Results', () => {
    const [isOutright, _setIsOutright] = useState(false);
    const [sportType, _setSportType] = useState(SportType.Soccer);
    const [dateFilter, _setDateFilter] = useState(ResultsDateFilter.Today);

    const today = getTodayWithGMT(-4);
    const dateFilterMapping: Record<ResultsDateFilter, IDateRange> = {
        [ResultsDateFilter.Today]: { startDate: today, endDate: today },
        [ResultsDateFilter.Minus1]: { startDate: addDay(today, -1), endDate: addDay(today, -1) },
        [ResultsDateFilter.Minus2]: { startDate: addDay(today, -2), endDate: addDay(today, -2) },
        [ResultsDateFilter.Minus3]: { startDate: addDay(today, -3), endDate: addDay(today, -3) },
        [ResultsDateFilter.OneWeek]: { startDate: addDay(today, -6), endDate: today },
        [ResultsDateFilter.TwoWeek]: { startDate: addDay(today, -13), endDate: today },
        [ResultsDateFilter.OneMonth]: { startDate: addDay(subMonth(today, 1), 1), endDate: today },
    };

    const dateOptions = computed(() => (isOutright.value
        ? [
            ResultsDateFilter.OneWeek,
            ResultsDateFilter.TwoWeek,
            ResultsDateFilter.OneMonth,
        ]
        : [
            ResultsDateFilter.Today,
            ResultsDateFilter.Minus1,
            ResultsDateFilter.Minus2,
            ResultsDateFilter.Minus3,
        ]));

    const [isLoading, setLoading] = useState(false);
    const [results, setResults] = useState<IResult[]>([]);
    const [outrightResults, setOutrightResults] = useState<IOutrightTournamentResult[]>([]);

    function formatDateString(date: Date) {
        return `${formatDate(date, 'yyyy-MM-dd')}T00:00:00-0400`;
    }

    const getMatchResults = async () => {
        try {
            setResults([]);
            setOutrightResults([]);
            setLoading(true);
            const date = dateFilterMapping[dateFilter.value];
            const sport = sportType.value;
            const startDate = formatDateString(date.startDate); // inclusive
            const endDate = formatDateString(addDay(date.endDate, 1)); // exclusive

            if (isOutright.value) {
                const outrightResults = await Api.getOutrightResults(sport, startDate, endDate);
                setOutrightResults(outrightResults);
            } else {
                const results = await Api.getMatchResults(sport, startDate);
                setResults(results);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const isEmpty = computed(() => (isOutright.value
        ? outrightResults.value.length === 0
        : results.value.length === 0));

    const groupedResults = computed(() => getGroupedResults(results.value));

    const groupedOutrightResults = computed(() => getGroupedOutrightResults(outrightResults.value));

    const [selectedLeague, _setSelectedLeague] = useState(0);

    const filteredLeagueResults = computed(() => {
        if (selectedLeague.value === 0) return groupedResults.value;
        return groupedResults.value.filter(result => result.id === selectedLeague.value);
    });
    const filteredLeagueOutrightResults = computed(() => {
        if (selectedLeague.value === 0) return groupedOutrightResults.value;
        return groupedOutrightResults.value.filter(result => result.tournamentId === selectedLeague.value);
    });

    const leagues = computed(() => {
        const sortFn = getLocalesSortingFn();

        if (isOutright.value) {
            const outrightLeagues = groupedResults.value.map(result => ({
                id: result.id,
                name: result.title,
            }));

            return outrightLeagues
                .sort((leagueA, leagueB) => sortFn(leagueA.name, leagueB.name));
        }

        const leagues = groupedResults.value.map(result => ({
            id: result.id,
            name: result.title,
        }));

        return leagues
            .sort((leagueA, leagueB) => sortFn(leagueA.name, leagueB.name));
    });

    const [isOpenMoreMatch, setIsOpenMoreMatch] = useState<number | null>(null);

    function setIsOutright(isOutright: boolean) {
        _setIsOutright(isOutright);
        _setDateFilter(isOutright ? ResultsDateFilter.OneWeek : ResultsDateFilter.Today);
        _setSelectedLeague(0);
        setIsOpenMoreMatch(null);
        getMatchResults();
    }

    function setSportType(sportType: SportType) {
        _setSportType(sportType);
        _setSelectedLeague(0);
        setIsOpenMoreMatch(null);
        getMatchResults();
    }

    function setDateFilter(dateFilter: ResultsDateFilter) {
        _setDateFilter(dateFilter);
        _setSelectedLeague(0);
        setIsOpenMoreMatch(null);
        getMatchResults();
    }

    function setSelectedLeague(leagueId: number) {
        _setSelectedLeague(leagueId);
        setIsOpenMoreMatch(null);
    }

    getMatchResults();

    watch(i18n.locale, () => {
        getMatchResults();
    });

    return {
        isOutright,
        setIsOutright,
        sportType,
        setSportType,
        dateOptions,
        dateFilter,
        setDateFilter,
        dateFilterMapping,
        isLoading,
        getMatchResults,
        isEmpty,
        groupedResults,
        leagues,
        selectedLeague,
        setSelectedLeague,
        filteredLeagueResults,
        isOpenMoreMatch,
        setIsOpenMoreMatch,
        filteredLeagueOutrightResults,
        groupedOutrightResults,
    };
});
