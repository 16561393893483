<template>
    <GroupTitle
        v-model:isExpanded="isExpanded"
        v-gtm:type="'GrpBetBuilder'"
        v-bind:expandedGtmLabel="isExpanded ? 'Colps' : 'Expd'"
        class="betBuilderResult"
    >
        <template #title>
            {{ i18n.t('market_type_name_41') }}
        </template>
    </GroupTitle>
    <div class="resultsRow_wrapper">
        <div v-if="isExpanded">
            <div
                v-for="result in marketResult"
                v-bind:key="`${result.marketType.id}-${result.point}`"
                class="resultRow resultRow_content"
            >
                <div
                    class="resultRow-item marketType"
                >
                    {{ result.marketType.name }}
                    <span v-if="result.point !== null" class="point">
                        [{{ formatMinDecimalPlace(result.point, 2) }}]
                    </span>
                </div>
                <div
                    class="resultRow-item result"
                >
                    <StatusTag
                        v-if="result.isRefunded"
                        v-bind:text="i18n.t('bet_status_5')"
                        size="sm"
                    />
                    <div v-else>
                        <template v-if="result.selectionResults.length === 0">
                            --
                        </template>
                        <template v-else>
                            <div v-for="(selectionResult, index) in result.selectionResults" v-bind:key="selectionResult.name">
                                {{ selectionResult.name }}<span v-if="index < result.selectionResults.length - 1">,</span>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import type { PropType } from 'vue';
    import { ref } from 'vue';
    import GroupTitle from '@/components/common/groupTitle/GroupTitle.vue';
    import StatusTag from '@/components/common/statusTag/StatusTag.vue';
    import { i18n } from '@/core/lib/i18n';
    import { formatMinDecimalPlace } from '@/core/lib/numberFormat';
    import type { IBetBuilderResult } from '@/interface/IResult';

    defineProps({
        marketResult: {
            type: Object as PropType<IBetBuilderResult[]>,
            required: true,
        },
    });

    const isExpanded = ref(true);
</script>

<style lang="scss" scoped>
    @import "@/components/results/BetBuilderResult";
</style>
