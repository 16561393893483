import { useTimeoutFn } from '@vueuse/core';
import type { CancelTokenSource } from 'axios';
import axios from 'axios';
import { storeToRefs } from 'pinia';
import type { Ref } from 'vue';
import { computed, watch } from 'vue';
import { cancelTicketMillisecond } from '@/components/betSlip/betSlipContext/BaseBetSlipContext';
import { useAutoRefreshBetSlip } from '@/components/betSlip/betSlipContext/useAutoRefreshBetSlip';
import { usePlaceBetErrorHandler } from '@/components/betSlip/betSlipContext/usePlaceBetErrorHandler.euro';
import { useState } from '@/composable/useState';
import { Api } from '@/core/lib/api';
import { i18n } from '@/core/lib/i18n';
import { isMainMarket } from '@/core/lib/oddsHelper';
import { getMaxPayout, getOpenTicketErrorMessage, getTotalOddsPrice } from '@/core/lib/ticket';
import { useOutrightInfoSubscriptionCallback } from '@/core/oddsApi/composable/useOutrightInfoSubscriptionCallback';
import { useOutrightLeagueQuery } from '@/core/oddsApi/composable/useOutrightLeagueQuery';
import { useTicketOutrightInfo } from '@/core/oddsApi/composable/useTicketOutrightInfo';
import type { IBetSlipEuro, ITicketEuro } from '@/interface/IBetSlip';
import { BetSlipType } from '@/interface/IBetSlip';
import type { IBetSlipContextEuro } from '@/interface/IBetSlipStoreEuro';
import { OpenTicketStatus, PriceStyle } from '@/interface/enum';
import { useEuroBetSlipStore } from '@/store/betSlipStore.euro';
import { useCustomerStore } from '@/store/customerStore';

export const createEuroOutrightBetSlipContext = (betSlip: Ref<IBetSlipEuro>): IBetSlipContextEuro => {
    const {
        useBetSlipsStoreByType,
        setBalance,
    } = useEuroBetSlipStore();

    const betSlipType = BetSlipType.Outright;
    const {
        isAutoAcceptChange,
        setBetSlipMessage,

        updateBet,
        removeBet,
    } = useBetSlipsStoreByType(betSlipType);
    const [message, setMessage] = useState<string>('');
    const cancelTicket = () => {
        removeBet(betSlip.value.key);
    };

    const totalPrice = computed(() => getTotalOddsPrice(betSlip.value.tickets));

    const singleTicket = computed(() => betSlip.value.tickets[0]);
    const maxPayout = computed(() => getMaxPayout(betSlip.value.stake, totalPrice.value, singleTicket.value.priceStyle));

    let cancelToken: CancelTokenSource;
    const openTicket = async () => {
        try {
            if (cancelToken) cancelToken.cancel('invalid previous openTicket');
            cancelToken = axios.CancelToken.source();

            const { data: ticketResponse } = await Api.openOutrightTicketEuro(singleTicket.value, cancelToken.token);
            setBalance(ticketResponse.balance ?? null);

            if (!ticketResponse.oddsInfo) return;

            const oddsData = ticketResponse.oddsInfo[singleTicket.value.key];
            if (!oddsData) return;

            if (oddsData.errorCode) {
                setBetSlipMessage(getOpenTicketErrorMessage(oddsData.errorCode));

                if (OpenTicketStatus.cancelTicketStatus.includes(oddsData.errorCode)) {
                    useTimeoutFn(() => cancelTicket(), cancelTicketMillisecond);
                }

                return;
            }

            const priceStyle = PriceStyle.fromPollux(oddsData.priceStyle);
            updateBet(betSlip.value.key, (betSlip) => {
                betSlip.uid = oddsData.uid ?? '';
                betSlip.minBet = ticketResponse.minBet;
                betSlip.maxBet = ticketResponse.maxBet;

                const ticket = betSlip.tickets.find(ticket => ticket.key === singleTicket.value.key);
                if (ticket) {
                    ticket.odds.betCondition = oddsData.betCondition ?? '';
                    ticket.odds.point = oddsData.point;
                    ticket.priceStyle = priceStyle;
                    ticket.priceOption.price = oddsData.price;

                    if (isMainMarket(ticket.odds)) {
                        ticket.event.homeTeam.liveScore = oddsData.liveHomeScore;
                        ticket.event.awayTeam.liveScore = oddsData.liveAwayScore;
                    }
                }

                return betSlip;
            });
        } catch (error) {
            if (axios.isCancel(error)) {
                // do nothing
            } else {
                console.error(error);
                setMessage(i18n.t('bet_slip_error_message_general_failure'));
                useTimeoutFn(() => cancelTicket(), cancelTicketMillisecond);
            }
        }
    };

    const { priceStyle } = storeToRefs(useCustomerStore());
    watch(priceStyle, () => {
        openTicket();
    });

    /**
     * Subscribe event and odds for ticket
     */
    const subscribeTicket = (ticket: Ref<ITicketEuro>) => {
        const { outrightLeagues } = useOutrightLeagueQuery({
            sportType: ticket.value.event.sportType,
            isLive: ticket.value.event.isLive,
            leagueId: ticket.value.event.league.id,
        });

        watch(outrightLeagues, (leagues) => {
            if (leagues.length > 0) {
                const league = leagues[0];
                singleTicket.value.event.league.name = league.name;
                singleTicket.value.event.league.info = league.info;
            }
        }, { deep: true });

        const { outrightOdds } = useTicketOutrightInfo({
            sportType: ticket.value.event.sportType,
            leagueId: ticket.value.event.league.id,
            oddsId: ticket.value.odds.id,
            isLive: ticket.value.odds.isLive,
        });

        watch(outrightOdds, (oddsList) => {
            if (oddsList.length > 0) {
                const odds = oddsList[0];
                singleTicket.value.event.homeTeam.name = odds.teamName ?? '';
                singleTicket.value.event.awayTeam.name = odds.teamName ?? '';
            }
        }, { deep: true });

        const {
            onUpdated,
            onDeleted,
        } = useOutrightInfoSubscriptionCallback({
            sportType: ticket.value.event.sportType,
            leagueId: ticket.value.event.league.id,
            oddsId: ticket.value.odds.id,
            isLive: ticket.value.odds.isLive,
        });

        onUpdated(() => {
            if (!betSlip.value.uid) return;

            openTicket();
        });

        onDeleted(() => {
            setBetSlipMessage(i18n.t('bet_slip_error_message_odds_closed'));
            useTimeoutFn(cancelTicket, cancelTicketMillisecond);
        });
    };

    openTicket();

    const { autoRefresh } = useAutoRefreshBetSlip(betSlip);
    autoRefresh(openTicket);

    watch(() => betSlip.value.placedOrderError, () => {
        const actions = {
            cancelTicket,
            openTicket,
        };
        const status = betSlip.value.placedOrderError.status;
        const { doErrorHandleActions } = usePlaceBetErrorHandler();
        doErrorHandleActions(status, actions);
    });

    return {
        betSlipType,

        message,
        isAutoAcceptChange,

        totalPrice,
        maxPayout,
        vouchers: computed(() => []),

        cancelTicket,
        subscribeTicket,
    };
};
