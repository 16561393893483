import { useThrottleFn, useTimeoutFn } from '@vueuse/core';
import type { CancelTokenSource } from 'axios';
import axios from 'axios';
import { cloneDeep, xorBy } from 'lodash';
import { storeToRefs } from 'pinia';
import type { Ref } from 'vue';
import { computed, ref, watch } from 'vue';
import { cancelTicketMillisecond } from '@/components/betSlip/betSlipContext/BaseBetSlipContext';
import { useAutoRefreshBetSlip } from '@/components/betSlip/betSlipContext/useAutoRefreshBetSlip';
import { usePlaceBetErrorHandler } from '@/components/betSlip/betSlipContext/usePlaceBetErrorHandler.euro';
import { useMpInvalidMessageDisplay } from '@/composable/useMpInvalidMessageDisplay';
import { useState } from '@/composable/useState';
import { Api } from '@/core/lib/api';
import { i18n } from '@/core/lib/i18n';
import { isMainMarket } from '@/core/lib/oddsHelper';
import { getMaxPayout, getOpenTicketErrorMessage, getTotalOddsPrice } from '@/core/lib/ticket';
import { useOddsSubscriptionCallback } from '@/core/oddsApi/composable/useOddsSubscriptionCallback';
import { useTicketEventEuro } from '@/core/oddsApi/composable/useTicketEvent.euro';
import { getOddsPresetFilterInputType } from '@/core/oddsApi/helpers';
import { EnumOddsCategoryType, EnumOddsStatus } from '@/core/oddsApi/oddsApiType';
import type { IBetSlipEuro, ITicketEuro } from '@/interface/IBetSlip';
import { BetSlipType } from '@/interface/IBetSlip';
import type { IBetSlipContextEuro } from '@/interface/IBetSlipStoreEuro';
import { BetSlipMessagePriority, OpenTicketStatus, PriceStyle } from '@/interface/enum';
import { useEuroBetSlipStore } from '@/store/betSlipStore.euro';
import { useCustomerStore } from '@/store/customerStore';

export const createEuroParlayBetSlipContext = (betSlip: Ref<IBetSlipEuro>): IBetSlipContextEuro => {
    const {
        setBalance,
        useBetSlipsStoreByType,
    } = useEuroBetSlipStore();
    const betSlipType = BetSlipType.Parlay;
    const {
        isAutoAcceptChange,
        setBetSlipMessage,

        updateBet,
        removeBet,
        removeAll,
    } = useBetSlipsStoreByType(betSlipType);
    const [message, setMessage] = useState<string>('');
    const cancelTicket = (key: string | undefined) => {
        if (key) removeBet(key);
    };
    const cancelAllTicket = () => {
        betSlip.value.tickets.forEach(t => removeBet(t.key));
    };

    const totalPrice = computed(() => getTotalOddsPrice(betSlip.value.tickets));
    // Parlay will always be Euro price style
    const maxPayout = computed(() => getMaxPayout(betSlip.value.stake, totalPrice.value, PriceStyle.Euro));

    const tickets = computed(() => betSlip.value.tickets);
    const { isValidSubBetCount } = useMpInvalidMessageDisplay(tickets, ref(true));

    let cancelToken: CancelTokenSource;
    const openTicket = async () => {
        try {
            if (!betSlip.value) return;
            if (!isValidSubBetCount.value) return;

            if (cancelToken) cancelToken.cancel('cancel previous openTicket');
            cancelToken = axios.CancelToken.source();

            const hash = getTicketsHash(betSlip);

            const { data: ticketResponse } = await Api.openMixParlayTicket(betSlip.value.tickets, cancelToken.token);
            setBalance(ticketResponse.balance ?? null);

            if (hash !== getTicketsHash(betSlip)) return; // tickets not match between request and response

            updateBet(betSlip.value.key, (betSlip) => {
                betSlip.tickets.forEach((ticket) => {
                    if (!ticketResponse.oddsInfo) return;

                    const ticketKey = ticket.key;
                    const oddsData = ticketResponse.oddsInfo[ticketKey];

                    if (!oddsData) {
                        setBetSlipMessage(i18n.t('bet_slip_error_message_odds_closed'));
                        useTimeoutFn(() => cancelTicket(ticketKey), cancelTicketMillisecond);
                        return;
                    }

                    if (oddsData.errorCode) {
                        if (OpenTicketStatus.cancelTicketStatus.includes(oddsData.errorCode)) {
                            setMessage(getOpenTicketErrorMessage(oddsData.errorCode));
                            useTimeoutFn(() => cancelTicket(ticketKey), cancelTicketMillisecond);
                        } else {
                            setBetSlipMessage(getOpenTicketErrorMessage(oddsData.errorCode));
                        }

                        return;
                    }

                    ticket.odds.betCondition = oddsData.betCondition ?? '';
                    ticket.odds.point = oddsData.point;
                    ticket.priceStyle = PriceStyle.fromPollux(oddsData.priceStyle);
                    ticket.priceOption.price = oddsData.price;

                    if (isMainMarket(ticket.odds)) {
                        ticket.event.homeTeam.liveScore = oddsData.liveHomeScore;
                        ticket.event.awayTeam.liveScore = oddsData.liveAwayScore;
                    }

                    ticket.liveHomeScore = oddsData.liveHomeScore;
                    ticket.liveAwayScore = oddsData.liveAwayScore;
                });

                betSlip.uid = ticketResponse.mpUid ?? '';
                betSlip.openTicketError = ticketResponse.maxBet < ticketResponse.minBet ? OpenTicketStatus.MaxOddsExceed : ticketResponse.errorCode;

                betSlip.minBet = ticketResponse.minBet;
                betSlip.maxBet = ticketResponse.maxBet;

                return betSlip;
            });
        } catch (error) {
            if (axios.isCancel(error)) {
                // do nothing
            } else {
                console.error(error);
                setBetSlipMessage(i18n.t('bet_slip_error_message_general_failure'));
                useTimeoutFn(() => removeAll(), 3000);
            }
        }
    };
    const openTicketThrottled = useThrottleFn(openTicket, 1000, true, false);

    const { priceStyle } = storeToRefs(useCustomerStore());
    watch(priceStyle, () => {
        openTicketThrottled();
    });

    /**
     * Subscribe event and odds for ticket
     */
    const subscribeTicket = (ticket: Ref<ITicketEuro>) => {
        const { event } = useTicketEventEuro(ticket.value.event.id, EnumOddsCategoryType.MixParlay);
        watch(event, (newEvent) => {
            if (newEvent) {
                const ticket = betSlip.value.tickets.find(ticket => ticket.event.id === newEvent.id);
                if (ticket) ticket.event = cloneDeep(newEvent);
            }
        });

        const {
            onUpdated,
            onDeleted,
        } = useOddsSubscriptionCallback({
            eventId: ticket.value.event.id,
            oddsId: ticket.value.odds.id,
            presetFilter: getOddsPresetFilterInputType(ticket.value.odds.isLive),
            oddsCategory: EnumOddsCategoryType.All,
            priceStyle,
        });

        onUpdated((odds) => {
            if (!betSlip.value.uid) return;
            if (odds.status === EnumOddsStatus.Suspend) {
                useTimeoutFn(cancelTicket, cancelTicketMillisecond);
                return;
            }

            openTicketThrottled();
        });

        onDeleted(() => {
            setBetSlipMessage(i18n.t('bet_slip_error_message_odds_closed'), BetSlipMessagePriority.SubscriptionOddsClose);
            useTimeoutFn(cancelTicket, cancelTicketMillisecond);
        });
    };

    const { autoRefresh } = useAutoRefreshBetSlip(betSlip);
    autoRefresh(openTicketThrottled);

    watch(betSlip, (newValue, oldValue) => {
        if (!newValue || newValue?.tickets.length === 0) {
            Api.clearTicket();
        } else {
            const ticketDifferences = xorBy(newValue.tickets, oldValue?.tickets ?? [], x => x.key);
            if (ticketDifferences.length) {
                updateBet(betSlip.value.key, (betSlip) => {
                    betSlip.uid = '';
                    betSlip.minBet = 0;
                    betSlip.maxBet = 0;
                });

                if (isValidSubBetCount.value) {
                    openTicketThrottled();
                }
            }
        }
    }, { immediate: true, deep: true });

    const { doErrorHandleActions } = usePlaceBetErrorHandler();
    doErrorHandleActions(betSlip.value.placedOrderError.status, {
        cancelTicket: cancelAllTicket,
        openTicket,
    });

    return {
        betSlipType,

        message,
        isAutoAcceptChange,

        totalPrice,
        maxPayout,
        vouchers: computed(() => []),

        cancelTicket,
        subscribeTicket,
    };
};

function getTicketsHash(betSlip: Ref<IBetSlipEuro>) {
    return betSlip.value?.tickets.map(x => x.key).join();
}
