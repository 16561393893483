import { useThrottleFn, useTimeoutFn } from '@vueuse/core';
import type { CancelTokenSource } from 'axios';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import { storeToRefs } from 'pinia';
import type { Ref } from 'vue';
import { computed, watch } from 'vue';
import { cancelTicketMillisecond } from '@/components/betSlip/betSlipContext/BaseBetSlipContext';
import { useAutoRefreshBetSlip } from '@/components/betSlip/betSlipContext/useAutoRefreshBetSlip';
import { usePlaceBetErrorHandler } from '@/components/betSlip/betSlipContext/usePlaceBetErrorHandler.euro';
import { useState } from '@/composable/useState';
import { Api } from '@/core/lib/api';
import { NonNull } from '@/core/lib/array';
import { i18n } from '@/core/lib/i18n';
import { isMainMarket } from '@/core/lib/oddsHelper';
import { getMaxPayout, getOpenTicketErrorMessage, getTotalOddsPrice, getVoucherMaxPayout } from '@/core/lib/ticket';
import { isVoucherValidForMixMaxBet, isVoucherValidForOdds } from '@/core/lib/voucherHelper';
import { useOddsSubscriptionCallback } from '@/core/oddsApi/composable/useOddsSubscriptionCallback';
import { useTicketEventEuro } from '@/core/oddsApi/composable/useTicketEvent.euro';
import { getOddsPresetFilterInputType } from '@/core/oddsApi/helpers';
import { EnumOddsCategoryType, EnumOddsStatus } from '@/core/oddsApi/oddsApiType';
import type { IBetSlipEuro, ITicketEuro } from '@/interface/IBetSlip';
import { BetSlipType } from '@/interface/IBetSlip';
import type { IBetSlipContextEuro } from '@/interface/IBetSlipStoreEuro';
import { BetSlipMessagePriority, OpenTicketStatus, PriceStyle, SiteStyle } from '@/interface/enum';
import { useEuroBetSlipStore } from '@/store/betSlipStore.euro';
import { useCustomerStore } from '@/store/customerStore';
import { useToggleStore } from '@/store/toggleStore';

export const createEuroSingleBetSlipContext = (betSlip: Ref<IBetSlipEuro>): IBetSlipContextEuro => {
    const {
        useBetSlipsStoreByType,
        setBalance,
        singleBetSlips,
    } = useEuroBetSlipStore();

    const betSlipType = BetSlipType.Single;
    const {
        isAutoAcceptChange,
        setBetSlipMessage,

        updateBet,
        removeBet,
    } = useBetSlipsStoreByType(betSlipType);

    const { isVoucherEnabled } = storeToRefs(useToggleStore(SiteStyle.Euro));

    const [_message, setMessage] = useState('');
    const selectedVoucherIds = computed(() => singleBetSlips.map(x => x.voucherId).filter(NonNull));
    const vouchers = computed(() => betSlip.value.vouchers.map(x => ({
        ...x,
        selected: selectedVoucherIds.value.includes(x.id),
    })));

    const isVoucherInvalid = computed(() => {
        if (!isVoucherEnabled.value) return false;

        if (!betSlip.value.voucherId) return false;

        const selectedVoucher = betSlip.value.vouchers.find(x => x.id === betSlip.value.voucherId);
        if (!selectedVoucher) {
            // maybe minBet or maxBet is changed?
            console.warn('voucherId is not in vouchers');
            return true;
        }

        const ticket = betSlip.value.tickets[0];
        if (!ticket) {
            console.warn('unexpected: failed to get ticket in single bet slip');
            return false;
        }

        const isVoucherValid = isVoucherValidForOdds(ticket.priceOption.price, ticket.priceStyle);
        if (isVoucherValid) return false;

        return true;
    });

    const voucherMessage = computed(() => (isVoucherInvalid.value
        ? i18n.t('voucher_invalid_message')
        : ''));

    const message = computed(() => _message.value || voucherMessage.value);

    const totalPrice = computed(() => getTotalOddsPrice(betSlip.value.tickets));
    const singleTicket = computed(() => betSlip.value.tickets[0]);
    const maxPayout = computed(() => (betSlip.value.voucherId
        ? getVoucherMaxPayout(betSlip.value.stake, totalPrice.value, singleTicket.value.priceStyle)
        : getMaxPayout(betSlip.value.stake, totalPrice.value, singleTicket.value.priceStyle)));

    const cancelTicket = () => {
        removeBet(betSlip.value.key);
    };

    const getVouchers = async () => {
        if (!isVoucherEnabled.value) return [];

        const ticketEvent = betSlip.value.tickets[0].event;
        return Api.getVouchers(ticketEvent.sportType, ticketEvent.league.id);
    };

    let cancelToken: CancelTokenSource;
    const openTicket = async (isNeedGetVouchers = false) => {
        try {
            if (cancelToken) cancelToken.cancel('cancel previous openTicket');
            cancelToken = axios.CancelToken.source();
            const [{ data: ticketResponse }, voucherResponse] = await Promise.all([
                Api.openSingleTicketEuro(singleTicket.value, cancelToken.token),
                isNeedGetVouchers ? getVouchers() : betSlip.value.vouchers ?? [],
            ]);

            setBalance(ticketResponse.balance ?? null);

            if (!ticketResponse.oddsInfo) return;

            const oddsData = ticketResponse.oddsInfo[singleTicket.value.key];
            if (!oddsData) return;

            if (oddsData.errorCode) {
                if (OpenTicketStatus.cancelTicketStatus.includes(oddsData.errorCode)) {
                    setMessage(getOpenTicketErrorMessage(oddsData.errorCode));
                    useTimeoutFn(() => cancelTicket(), cancelTicketMillisecond);
                } else {
                    setBetSlipMessage(getOpenTicketErrorMessage(oddsData.errorCode));
                }

                return;
            }

            updateBet(betSlip.value.key, (betSlip) => {
                betSlip.uid = oddsData.uid ?? '';
                betSlip.minBet = ticketResponse.minBet;
                betSlip.maxBet = ticketResponse.maxBet;
                betSlip.openTicketError = ticketResponse.errorCode;

                const ticket = betSlip.tickets[0];
                ticket.odds.betCondition = oddsData.betCondition ?? '';
                ticket.odds.point = oddsData.point;
                ticket.priceStyle = PriceStyle.fromPollux(oddsData.priceStyle);
                ticket.priceOption.price = oddsData.price;

                if (isMainMarket(ticket.odds)) {
                    ticket.event.homeTeam.liveScore = oddsData.liveHomeScore;
                    ticket.event.awayTeam.liveScore = oddsData.liveAwayScore;
                }

                ticket.liveHomeScore = oddsData.liveHomeScore;
                ticket.liveAwayScore = oddsData.liveAwayScore;

                return betSlip;
            });

            betSlip.value.vouchers = voucherResponse
                .filter(voucher => isVoucherValidForMixMaxBet(
                    voucher.stake,
                    ticketResponse.minBet,
                    ticketResponse.maxBet,
                ));
        } catch (error) {
            if (axios.isCancel(error)) {
                // do nothing
            } else {
                console.error(error);
                setMessage(i18n.t('bet_slip_error_message_general_failure'));
                useTimeoutFn(() => cancelTicket(), cancelTicketMillisecond);
            }
        }
    };
    const openTicketThrottled = useThrottleFn(openTicket, 1000, true, false);

    const { priceStyle } = storeToRefs(useCustomerStore());
    watch(priceStyle, () => {
        openTicketThrottled();
    });

    /**
     * Subscribe event and odds for ticket
     */
    const subscribeTicket = (ticket: Ref<ITicketEuro>) => {
        const { event } = useTicketEventEuro(ticket.value.event.id, EnumOddsCategoryType.All);
        watch(event, (newEvent) => {
            if (newEvent) {
                const ticket = betSlip.value.tickets.find(ticket => ticket.event.id === newEvent.id);
                if (ticket) ticket.event = cloneDeep(newEvent);
            }
        });

        const { onUpdated, onDeleted } = useOddsSubscriptionCallback({
            eventId: ticket.value.event.id,
            oddsId: ticket.value.odds.id,
            presetFilter: getOddsPresetFilterInputType(ticket.value.odds.isLive),
            oddsCategory: EnumOddsCategoryType.All,
            priceStyle,
        });

        onUpdated((odds) => {
            if (!betSlip.value.uid) return;
            if (odds.status === EnumOddsStatus.Suspend) {
                useTimeoutFn(cancelTicket, cancelTicketMillisecond);
                return;
            }

            openTicketThrottled();
        });

        onDeleted(() => {
            setBetSlipMessage(i18n.t('bet_slip_error_message_odds_closed'), BetSlipMessagePriority.SubscriptionOddsClose);
            useTimeoutFn(cancelTicket, cancelTicketMillisecond);
        });
    };

    const { autoRefresh } = useAutoRefreshBetSlip(betSlip);
    autoRefresh(openTicketThrottled);

    watch(() => betSlip.value.placedOrderError, () => {
        const actions = {
            cancelTicket,
            openTicket,
        };
        const status = betSlip.value.placedOrderError.status;
        const { doErrorHandleActions } = usePlaceBetErrorHandler();
        doErrorHandleActions(status, actions);
    });

    openTicket(true);

    return {
        betSlipType,

        message,
        isAutoAcceptChange,

        totalPrice,
        maxPayout,
        vouchers,

        cancelTicket,
        subscribeTicket,
    };
};
