import { useBroadcastChannel } from '@vueuse/core';
import type { ComputedRef, InjectionKey, Ref } from 'vue';
import { computed, watchEffect } from 'vue';
import { useAutoRefreshBetSlip } from '@/components/betSlip/betSlipContext/useAutoRefreshBetSlip';
import { useAutoResetState } from '@/composable/useAutoResetState';
import { useStakeDisplay } from '@/composable/useStakeDisplay';
import { useStakeInput } from '@/composable/useStakeInput';
import { useState } from '@/composable/useState';
import { useToggle } from '@/composable/useToggle';
import { defineContext } from '@/core/lib/defineContext';
import { i18n } from '@/core/lib/i18n';
import { getActualStake, getTotalOddsPrice } from '@/core/lib/ticket';
import type { IBetSlip, ITicket } from '@/interface/IBetSlip';
import { BetSlipType } from '@/interface/IBetSlip';
import type { IVoucher } from '@/interface/IVoucher';
import type { IBetBuilderBetSlip } from '@/interface/betBuilder';
import type { BetSlipBroadcastMessage } from '@/interface/broadcastchannel';
import { BetSlipMessagePriority, BroadcastChannelName, StakeValidStatus } from '@/interface/enum';

export const BetSlipContextKey: InjectionKey<IAsiaBetSlipContext> = Symbol('BetSlipContext');

export interface IAsiaBetSlipContext extends ReturnType<typeof useBaseBetSlipContext> {
    maxPayout: ComputedRef<string>;
    isVoucherAvailable: ComputedRef<boolean>;
    isVoucherValidForTicket: ComputedRef<boolean>;
    openTicket: (shouldUpdateStake: boolean) => Promise<void>;
    cancelTicket: (key?: string | undefined) => void;
    placeBet: () => Promise<void>;
    subscribeOdds: (ticket: Ref<ITicket>) => void;
    updateWhenLangChanged: (ticket: Ref<ITicket>) => void;
}

interface BetSlipMessage{
    message: string;
    priority: BetSlipMessagePriority;
}

export const cancelTicketMillisecond = 3000;

export const defineBetSlipContext = (contextFunc: (betSlip: Ref<IBetSlip>) => IAsiaBetSlipContext) => defineContext(BetSlipContextKey, contextFunc);

export const useBaseBetSlipContext = (betSlip: Ref<IBetSlip | IBetBuilderBetSlip>) => {
    // Message
    const [stakeMessage, setStakeMessage] = useState('');
    const [_betSlipMessage, _setBetSlipMessage] = useAutoResetState<BetSlipMessage>({ message: '', priority: BetSlipMessagePriority.Unknown }, 3000);
    const betSlipMessage = computed(() => _betSlipMessage.value.message);
    const setBetSlipMessage = (message: string, priority: BetSlipMessagePriority = BetSlipMessagePriority.Error) => {
        if (priority < _betSlipMessage.value.priority) return;
        _setBetSlipMessage({
            message, priority,
        });
    };
    const [vouchers, setVouchers] = useState<IVoucher[]>([]);
    const [selectedVoucher, setSelectedVoucher] = useState<IVoucher | null>(null);
    const [voucherEnabled, setVoucherEnabled] = useState(false);

    // Place bet
    const stakeInput = useStakeInput();
    const { stake } = stakeInput;

    const totalOddsPrice = computed(() => (betSlip.value.type === BetSlipType.BetBuilder ? betSlip.value.price.value : getTotalOddsPrice(betSlip.value.tickets)));
    const minBetDisplay = computed(() => useStakeDisplay(Math.max(0, betSlip.value.minBet)).stakeDisplay);
    const maxBetDisplay = computed(() => useStakeDisplay(Math.max(0, betSlip.value.maxBet)).stakeDisplay);

    const actualStake = computed(() => (selectedVoucher.value !== null ? 0 : getActualStake(stake.value, totalOddsPrice.value)));
    const isStakeMoreThanMaxBet = computed(() => stake.value > betSlip.value.maxBet);
    const isStakeLessThanMinBet = computed(() => stake.value < betSlip.value.minBet);
    const isVoucherMoreThanMaxBet = computed(() => (selectedVoucher.value ? selectedVoucher.value.stake > betSlip.value.maxBet : false));
    const isVoucherLessThanMinBet = computed(() => (selectedVoucher.value ? selectedVoucher.value.stake < betSlip.value.minBet : false));
    const isStakeMoreThanBalance = computed(() => (betSlip.value.balance !== null ? actualStake.value > betSlip.value.balance : false));
    const isBalanceLessThanMinBet = computed(() => (betSlip.value.balance !== null ? betSlip.value.balance < betSlip.value.minBet : false));
    const [isPlaceBetInProgress, toggleIsPlaceBetInProgress] = useToggle(false);
    const isPlaceBetEnabled = computed(() => {
        const isOpenTicketSuccess = !!betSlip.value.uid;
        if (!isOpenTicketSuccess) return false;
        if (isPlaceBetInProgress.value) return false;

        const isBalanceEnoughToPlaceBet = !isBalanceLessThanMinBet.value;
        return voucherEnabled.value ? selectedVoucher.value !== null : isBalanceEnoughToPlaceBet;
    });

    watchEffect(() => {
        if (voucherEnabled.value) {
            setStakeMessage('');
            return;
        }

        if (isBalanceLessThanMinBet.value) {
            setStakeMessage(i18n.t('bet_slip_error_message_balance_less_than_min_bet'));
        }
    });

    type StakeStatus = {
        status: StakeValidStatus.Valid;
    } | {
        status: StakeValidStatus;
        adjustStake: number;
        stakeMessage: string;
    }
    const checkIsValidStake = (): StakeStatus => {
        if (isStakeMoreThanMaxBet.value) {
            return {
                adjustStake: Math.min(betSlip.value.maxBet, betSlip.value.balance ?? betSlip.value.maxBet),
                stakeMessage: i18n.t('bet_slip_error_message_adjust_stake_max_bet'),
                status: StakeValidStatus.StakeMoreThanMaxBet,
            };
        }

        if (isStakeLessThanMinBet.value) {
            return {
                adjustStake: betSlip.value.minBet,
                stakeMessage: i18n.t('bet_slip_error_message_adjust_stake_min_bet'),
                status: StakeValidStatus.StakeLessThanMinBet,
            };
        }

        if (isStakeMoreThanBalance.value) {
            return {
                adjustStake: Math.floor(betSlip.value.balance ?? 0),
                stakeMessage: i18n.t('bet_slip_error_message_adjust_stake_balance'),
                status: StakeValidStatus.StakeMoreThanBalance,
            };
        }

        return { status: StakeValidStatus.Valid };
    };

    const checkIsValidVoucher = (): StakeStatus => {
        if (isVoucherMoreThanMaxBet.value) {
            return {
                adjustStake: 0,
                stakeMessage: i18n.t('bet_slip_error_message_voucher_stake_exceeds_max_bet'),
                status: StakeValidStatus.VoucherMoreThanMaxBet,
            };
        }

        if (isVoucherLessThanMinBet.value) {
            return {
                adjustStake: 0,
                stakeMessage: i18n.t('bet_slip_error_message_voucher_stake_less_than_min_bet'),
                status: StakeValidStatus.VoucherLessThanMinBet,
            };
        }

        return { status: StakeValidStatus.Valid };
    };

    const { post } = useBroadcastChannel<BetSlipBroadcastMessage, BetSlipBroadcastMessage>({ name: BroadcastChannelName.BetSlip });
    function emitPlaceBetSuccess() {
        const isLive = betSlip.value.type === BetSlipType.BetBuilder
            ? betSlip.value.tickets[0].match.isLive
            : betSlip.value.tickets.some(ticket => ticket.odds.isLive);
        post({ name: 'placeBetSuccess', isLive });
    }

    const { autoRefresh } = useAutoRefreshBetSlip(betSlip);

    return {
        betSlip,
        stakeInput,
        vouchers,
        setVouchers,
        voucherEnabled,
        setVoucherEnabled,
        selectedVoucher,
        setSelectedVoucher,
        totalOddsPrice,
        betSlipMessage,
        setBetSlipMessage,
        stakeMessage,
        setStakeMessage,
        minBetDisplay,
        maxBetDisplay,
        checkIsValidStake,
        checkIsValidVoucher,
        isPlaceBetEnabled,
        isBalanceLessThanMinBet,
        isStakeLessThanMinBet,
        isStakeMoreThanMaxBet,
        isStakeMoreThanBalance,
        cancelTicketMillisecond,
        isPlaceBetInProgress,
        toggleIsPlaceBetInProgress,
        emitPlaceBetSuccess,
        autoRefresh,
    };
};
