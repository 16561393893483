import { defineStore, storeToRefs } from 'pinia';
import type { Ref } from 'vue';
import { computed } from 'vue';
import { useInterval } from '@/composable/useInterval';
import { useState } from '@/composable/useState';
import { Api } from '@/core/lib/api';
import { parseDate } from '@/core/lib/date';
import { invertCapitalize } from '@/core/lib/string';
import type { IBehaviorMinutes } from '@/interface/IBehaviorMinutes';
import type { ILiveScoreProviderConfig } from '@/interface/ILiveScoreProviderConfig';
import type { IBetBuilderLegSetting } from '@/interface/betBuilder';
import type { BehaviorType } from '@/interface/enum';
import { PlayerType, SiteStyle, SportType } from '@/interface/enum';
import { useCustomerStore } from '@/store/customerStore';

const toEnable = (input: string | undefined) => input?.toLocaleLowerCase() === 'y';
const toArray = (input: string | undefined, splitter = ',') => input?.split(splitter) ?? [];
const toInteger = (input: string | undefined) => {
    const result = parseInt(input ?? '');
    if (Number.isNaN(result)) {
        throw new Error(`Failed to parse integer from ${input}`);
    }
    return result;
};
const toNumberArray = (input: string | undefined, splitter = ',') => toArray(input, splitter).map(x => parseInt(x));
const toEnumArray = <T>(input: string | undefined, splitter = ',') => toArray(input, splitter).map(x => parseInt(x) as unknown as T);
const toObject = <T, D = T>(input: string | undefined, defaultValue: D): T | D => {
    if (input === undefined) return defaultValue;

    try {
        return JSON.parse(input, (_, value) => {
            if (value && typeof value === 'object' && !Array.isArray(value)) {
                return Object.keys(value).reduce((newObj: Record<string, any>, k) => {
                    newObj[invertCapitalize(k)] = value[k];
                    return newObj;
                }, {});
            }
            return value;
        }) as unknown as T;
    } catch (e) {
        return defaultValue;
    }
};
const expiredDateToEnable = (date: string | undefined) => {
    if (!date) return false;

    const now = new Date();
    return now < parseDate(date, "yyyy-MM-dd'T'HH:mm:ss", 8);
};

/**
 * Variation of useState, with a setter that protects against undefined values and parses input string using the provided parser function.
 * @param initialState
 * @param parser function to parse string into the generic type T
 */
function useFeatureToggleState<T>(initialState: T, parser = (stringValue: string) => stringValue as T): [Ref<T>, (stringValue?: string) => void] {
    const [state, _setState] = useState(initialState);

    const setState = (stringValue?: string) => {
        if (stringValue === undefined) return;

        try {
            const parsed = parser(stringValue);
            _setState(parsed);
        } catch (e) {
            console.error('Failed to parse feature toggle state', stringValue, e);
        }
    };

    return [state, setState];
}

const _toggles = () => {
    /**
     * @internal This is only used to check if the toggle store is initialized with the correct siteStyle.
     *
     * You should not use this value for any other purpose.
     */
    const [_siteStyle, setSiteType] = useState(SiteStyle.Unknown);

    const [isInLiveStreamWhiteList, setIsInLiveStreamWhiteList] = useFeatureToggleState(false, toEnable);
    const [isESportsImInSportEnabled, setIsESportsImInSportEnabled] = useFeatureToggleState(false, toEnable);
    const [isGLiveStreamEnabled, setIsGLiveStreamEnabled] = useFeatureToggleState(false, toEnable);
    const [isAvApiLiveStreamEnabled, setIsAvApiLiveStreamEnabled] = useFeatureToggleState(false, toEnable);
    const [liveStreamRefreshSeconds, setLiveStreamRefreshSeconds] = useFeatureToggleState(30, toInteger);
    const [gLiveStreamRestrictions, setGLiveStreamRestrictions] = useFeatureToggleState<string[]>([], s => toArray(s, '|'));
    const [excludeSportList, setExcludeSportList] = useFeatureToggleState<SportType[]>([], toEnumArray);
    const [defaultOpenMoreMarketInLiveLeagueIds, setDefaultOpenMoreMarketInLiveLeagueIds] = useFeatureToggleState([], toNumberArray);
    const [defaultOpenMoreMarketInLiveBySportsIds, setDefaultOpenMoreMarketInLiveBySportsIds] = useFeatureToggleState([], toEnumArray);
    const [supportFailedBetReasonSports, setSupportFailedBetReasonSports] = useFeatureToggleState<SportType[]>([], toEnumArray);
    const [liveScoreRefreshSeconds, setLiveScoreRefreshSeconds] = useFeatureToggleState(30, toInteger);
    const [miniMyBetRefreshSeconds, setMiniMyBetRefreshSeconds] = useFeatureToggleState(120, toInteger);
    const [miniMyBetWaitingRefreshSeconds, setMiniMyBetWaitingRefreshSeconds] = useFeatureToggleState(5, toInteger);
    const [myBetRefreshSeconds, setMyBetRefreshSeconds] = useFeatureToggleState(120, toInteger);
    const [myBetWaitingRefreshSeconds, setMyBetWaitingRefreshSeconds] = useFeatureToggleState(5, toInteger);
    const [multipleSportsLeagueNames, setMultipleSportsLeagueNames] = useFeatureToggleState<string[]>([], toArray);
    const [eFootballLeagueIds, setEFootballLeagueIds] = useFeatureToggleState([], toNumberArray);
    const [isCasinoWidgetEnabled, setIsCasinoWidgetEnabled] = useFeatureToggleState(false, toEnable);
    const [isMiniGameWidgetEnabled, setIsMiniGameWidgetEnabled] = useFeatureToggleState(false, toEnable);
    const [isVirtualFootballEnabled, setIsVirtualFootballEnabled] = useFeatureToggleState(false, toEnable);
    const [isVirtualBasketballEnabled, setIsVirtualBasketballEnabled] = useFeatureToggleState(false, toEnable);
    const [_isVoucherEnabled, setIsVoucherEnabled] = useFeatureToggleState(false, toEnable);
    const [cashOutValueRefreshSeconds, setCashOutValueRefreshSeconds] = useFeatureToggleState(5, toInteger);
    const [isCashOutEnabled, setCashOutEnabled] = useFeatureToggleState(false, toEnable);
    const [isMyFavoriteEnabled, setIsMyFavoriteEnabled] = useFeatureToggleState(false, toEnable);
    const [liveTicketAutoRefreshSeconds, setLiveTicketAutoRefreshSeconds] = useFeatureToggleState(3, toInteger);
    const [nonLiveTicketAutoRefreshSeconds, setNonLiveTicketAutoRefreshSeconds] = useFeatureToggleState(5, toInteger);
    const [isSendBehaviorEnabled, setIsSendBehaviorEnabled] = useFeatureToggleState(false, toEnable);
    const [behaviorMinutes, setBehaviorMinutes] = useFeatureToggleState<IBehaviorMinutes>({}, s => toObject(s, {}));
    const [isScoreAlertEnabled, setIsScoreAlertEnabled] = useFeatureToggleState(false, toEnable);
    const [miniMyBetMaxDisplayCount, setMiniMyBetMaxDisplayCount] = useFeatureToggleState(15, toInteger);
    const [isShowTvChannelsTooltip, setIsShowTvChannelsTooltip] = useFeatureToggleState(false, toEnable);
    const [liveRefreshDuration, setLiveRefreshDuration] = useFeatureToggleState(20, parseInt);
    const [nonLiveRefreshDuration, setNonLiveRefreshDuration] = useFeatureToggleState(60, parseInt);
    const [oddsApiUrl, setOddsApiUrl] = useFeatureToggleState('');
    const [oddsApiWsUrl, setOddsApiWsUrl] = useFeatureToggleState('');
    const [isBetBuilderEnabled, setIsBetBuilderEnabled] = useFeatureToggleState(false, toEnable);
    const [betBuilderApiUrl, setBetBuilderApiUrl] = useFeatureToggleState('');
    const [betBuilderWsUrl, setBetBuilderWsUrl] = useFeatureToggleState('');
    const [betBuilderMinLegCount, setBetBuilderMinLegCount] = useFeatureToggleState(0, parseInt);
    const [betBuilderMinLegSettings, setBetBuilderMinLegSettings] = useFeatureToggleState<IBetBuilderLegSetting[]>([], setting => toObject(setting, []));
    const [placeBetDelayTime, setPlaceBetDelayTime] = useFeatureToggleState(0, parseInt);
    const [liveScoreProviderConfig, setLiveScoreProviderConfig] = useFeatureToggleState<ILiveScoreProviderConfig>({}, state => toObject(state, {}));
    const [setGameScoreSportList, setSetGameScoreSportList] = useFeatureToggleState<SportType[]>([], toEnumArray);
    const [periodSportList, setPeriodSportList] = useFeatureToggleState<SportType[]>([], toEnumArray);
    const [inningSportList, setInningSportList] = useFeatureToggleState<SportType[]>([], toEnumArray);
    const [sportsCupTournamentNames, setSportsCupTournamentNames] = useFeatureToggleState<string[]>([], toArray);
    const [sportsCup2TournamentNames, setSportsCup2TournamentNames] = useFeatureToggleState<string[]>([], toArray);
    const [isShowMultipleSportsHotLink, setIsShowMultipleSportsHotLink] = useFeatureToggleState(false, expiredDateToEnable);
    const [isShowSportCupHotLink, setIsShowSportCupHotLink] = useFeatureToggleState(false, expiredDateToEnable);
    const [isShowSportCup2HotLink, setIsShowSportCup2HotLink] = useFeatureToggleState(false, expiredDateToEnable);
    const [isEFootballZoneEnabled, setIsEFootballZoneEnabled] = useFeatureToggleState(false, toEnable);
    const [isUseNewPlaceBetApi, setIsUseNewPlaceBetApi] = useFeatureToggleState(false, toEnable);
    const [mpTwoSubBetLeagueIds, setMpTwoSubBetLeagueIds] = useFeatureToggleState([], toNumberArray);
    const [isMpTwoSubBetEnabled, setIsMpTwoSubBetEnabled] = useFeatureToggleState(false, toEnable);
    const [_isJackpotEnabled, setIsJackpotEnabled] = useFeatureToggleState(false, toEnable);
    const [multipleSportsZoneSupportSports, setMultipleSportsZoneSupportSports] = useFeatureToggleState<SportType[]>([], toEnumArray);
    const [b2cJackpotCurrency, setB2cJackpotCurrency] = useFeatureToggleState<string[]>([], toArray);
    const [b2bJackpotCurrency, setB2bJackpotCurrency] = useFeatureToggleState<string[]>([], toArray);
    const [toggleAutoRefreshSeconds, setToggleAutoRefreshSeconds] = useFeatureToggleState(60, toInteger);
    const [isLiveStreamBanner1Enabled, setIsLiveStreamBanner1Enabled] = useFeatureToggleState(false, toEnable);
    const [isLiveStreamBanner2Enabled, setIsLiveStreamBanner2Enabled] = useFeatureToggleState(false, toEnable);
    const [liveStreamBannerEnableMatchIds, setLiveStreamBannerEnableMatchIds] = useFeatureToggleState([], toNumberArray);
    const [isBetBuilderTutorialEnabled, setIsBetBuilderTutorialEnabled] = useFeatureToggleState(false, toEnable);
    const [isBetBuilderResultEnabled, setIsBetBuilderResultEnabled] = useFeatureToggleState(false, toEnable);
    const [defaultSportType, setDefaultSportType] = useFeatureToggleState(SportType.Soccer, toInteger);
    const [isFairExchangeEnabled, setIsFairExchangeEnabled] = useFeatureToggleState(false, toEnable);
    const [isIdleMonitorEnabled, setIsIdleMonitorEnabled] = useFeatureToggleState(false, toEnable);

    async function initToggle(siteStyle: SiteStyle) {
        try {
            setSiteType(siteStyle);

            const { data: toggleResponse } = await Api.getToggle(siteStyle);
            setIsInLiveStreamWhiteList(toggleResponse.isInAvApiWhiteList);
            setIsESportsImInSportEnabled(toggleResponse.isESportsImInSportEnabled);
            setIsGLiveStreamEnabled(toggleResponse.isGLiveStreamEnabled);
            setIsAvApiLiveStreamEnabled(toggleResponse.isAvApiLiveStreamEnabled);
            setLiveStreamRefreshSeconds(toggleResponse.liveStreamRefreshSeconds);
            setGLiveStreamRestrictions(toggleResponse.gLiveStreamRestrictions);
            setExcludeSportList(toggleResponse.excludeSportList);
            setDefaultOpenMoreMarketInLiveLeagueIds(toggleResponse.defaultOpenMoreMarketInLiveLeagueIds);
            setDefaultOpenMoreMarketInLiveBySportsIds(toggleResponse.defaultOpenMoreMarketInLiveBySportsIds);
            setSupportFailedBetReasonSports(toggleResponse.supportFailedBetReasonSports);
            setLiveScoreRefreshSeconds(toggleResponse.liveScoreRefreshSeconds);
            setMiniMyBetRefreshSeconds(toggleResponse.miniMyBetRefreshSeconds);
            setMiniMyBetWaitingRefreshSeconds(toggleResponse.miniMyBetWaitingRefreshSeconds);
            setMyBetRefreshSeconds(toggleResponse.myBetRefreshSeconds);
            setMyBetWaitingRefreshSeconds(toggleResponse.myBetWaitingRefreshSeconds);
            setMultipleSportsLeagueNames(toggleResponse.multipleSportsTournamentNames);
            setEFootballLeagueIds(toggleResponse.efootballLeagueIds);
            setIsCasinoWidgetEnabled(toggleResponse.isCasinoWidgetEnabled);
            setIsMiniGameWidgetEnabled(toggleResponse.isMiniGameWidgetEnabled);
            setIsVirtualFootballEnabled(toggleResponse.isVirtualFootballEnabled);
            setIsVirtualBasketballEnabled(toggleResponse.isVirtualBasketballEnabled);
            setIsVoucherEnabled(toggleResponse.isVoucherEnabled);
            setCashOutValueRefreshSeconds(toggleResponse.cashOutValueRefreshSeconds);
            setCashOutEnabled(toggleResponse.isCashOutEnabled);
            setIsMyFavoriteEnabled(toggleResponse.isMyFavoritesEnabled);
            setLiveTicketAutoRefreshSeconds(toggleResponse.liveTicketAutoRefreshSeconds);
            setNonLiveTicketAutoRefreshSeconds(toggleResponse.nonLiveTicketAutoRefreshSeconds);
            setIsSendBehaviorEnabled(toggleResponse.isSendBehaviorEnabled);
            setBehaviorMinutes(toggleResponse.behaviorMinutes);
            setIsScoreAlertEnabled(toggleResponse.isScoreAlertEnabled);
            setMiniMyBetMaxDisplayCount(toggleResponse.miniMyBetMaxDisplayCount);
            setIsShowTvChannelsTooltip(toggleResponse.isShowTvChannelsTooltip);
            setLiveRefreshDuration(toggleResponse.liveRefreshDuration);
            setNonLiveRefreshDuration(toggleResponse.nonLiveRefreshDuration);
            setOddsApiUrl(toggleResponse.oddsApiUrl);
            setOddsApiWsUrl(toggleResponse.oddsApiWsUrl);
            setIsBetBuilderEnabled(toggleResponse.isBetBuilderEnabled);
            setBetBuilderApiUrl(toggleResponse.betBuilderOddsApiUrl);
            setBetBuilderWsUrl(toggleResponse.betBuilderOddsWsUrl);
            setBetBuilderMinLegCount(toggleResponse.betBuilderMinLegCount);
            setBetBuilderMinLegSettings(toggleResponse.betBuilderMinLegSettings);
            setPlaceBetDelayTime(toggleResponse.placeBetDelayTime);
            setLiveScoreProviderConfig(toggleResponse.liveScoreProviderConfig);
            setSetGameScoreSportList(toggleResponse.setGameScoreSportList);
            setPeriodSportList(toggleResponse.periodSportList);
            setInningSportList(toggleResponse.inningSportList);
            setSportsCupTournamentNames(toggleResponse.sportsCupTournamentNames);
            setSportsCup2TournamentNames(toggleResponse.sportsCup2TournamentNames);
            setIsShowMultipleSportsHotLink(toggleResponse.multipleSportsHotLinkExpiredDate);
            setIsShowSportCupHotLink(toggleResponse.sportsCupHotLinkExpiredDate);
            setIsShowSportCup2HotLink(toggleResponse.sportsCup2HotLinkExpiredDate);
            setIsEFootballZoneEnabled(toggleResponse.isEFootballZoneEnabled);
            setIsUseNewPlaceBetApi(toggleResponse.isUseNewPlaceBetApi);
            setMpTwoSubBetLeagueIds(toggleResponse.mpTwoSubBetLeagueIds);
            setIsMpTwoSubBetEnabled(toggleResponse.isTwoMpSubBetEnabled);
            setIsJackpotEnabled(toggleResponse.isJackpotEnabled);
            setMultipleSportsZoneSupportSports(toggleResponse.multipleSportsZoneSupportSports);
            setB2bJackpotCurrency(toggleResponse.b2bJackpotCurrency);
            setB2cJackpotCurrency(toggleResponse.b2cJackpotCurrency);
            setToggleAutoRefreshSeconds(toggleResponse.toggleAutoRefreshSeconds);
            setIsLiveStreamBanner1Enabled(toggleResponse.isLiveStreamBanner1Enabled);
            setIsLiveStreamBanner2Enabled(toggleResponse.isLiveStreamBanner2Enabled);
            setLiveStreamBannerEnableMatchIds(toggleResponse.liveStreamBannerEnableMatchIds);
            setIsBetBuilderTutorialEnabled(toggleResponse.isBetBuilderTutorialEnabled);
            setIsBetBuilderResultEnabled(toggleResponse.isBetBuilderResultEnabled);
            setDefaultSportType(toggleResponse.defaultSportType);
            setIsFairExchangeEnabled(toggleResponse.isFairExchangeEnabled);
            setIsIdleMonitorEnabled(toggleResponse.isIdleMonitorEnabled);
        } catch (error) {
            console.error('initToggle: ', error);
        }
    }

    const { siteStyle, isMnlB2C, playerType, isEFootballEnabled, sboCurrency } = storeToRefs(useCustomerStore());
    function registerAutoRefresh() {
        const isEnabledAutoRefresh = computed(() => toggleAutoRefreshSeconds.value > 0);

        useInterval(
            () => {
                initToggle(siteStyle.value);
            },
            toggleAutoRefreshSeconds,
            {
                isEnabled: isEnabledAutoRefresh,
            },
        );
    }

    function getBehaviorMinutes(type: BehaviorType): number {
        const config = behaviorMinutes.value;
        if (type in config) {
            return config[type] ?? 0;
        }
        return 0;
    }

    const isVoucherEnabled = computed(() => _isVoucherEnabled.value && isMnlB2C.value);

    const isJackpotEnabled = computed(() => {
        if (!_isJackpotEnabled.value) return false;

        if (!isEFootballEnabled.value || playerType.value === PlayerType.B2B2C) return false;

        const whitelist = playerType.value === PlayerType.B2C
            ? b2cJackpotCurrency.value
            : b2bJackpotCurrency.value;

        return whitelist.map(c => c.toUpperCase()).includes(sboCurrency.value.toUpperCase());
    });

    function getBetBuilderMinSelectionCount(leagueId: number) {
        return betBuilderMinLegSettings.value.find(setting => setting.leagueId === leagueId)?.minBetBuilderLegs ?? betBuilderMinLegCount.value;
    }

    return {
        initToggle,
        registerAutoRefresh,
        _siteStyle,

        isESportsImInSportEnabled,
        isGLiveStreamEnabled,
        isAvApiLiveStreamEnabled,
        gLiveStreamRestrictions,
        liveStreamRefreshSeconds,
        excludeSportList,
        defaultOpenMoreMarketInLiveLeagueIds,
        defaultOpenMoreMarketInLiveBySportsIds,
        isInLiveStreamWhiteList,
        supportFailedBetReasonSports,
        liveScoreRefreshSeconds,
        miniMyBetRefreshSeconds,
        miniMyBetWaitingRefreshSeconds,
        myBetRefreshSeconds,
        myBetWaitingRefreshSeconds,
        eFootballLeagueIds,
        isCasinoWidgetEnabled,
        isMiniGameWidgetEnabled,
        isVirtualFootballEnabled,
        isVirtualBasketballEnabled,
        isVoucherEnabled,
        isCashOutEnabled,
        cashOutValueRefreshSeconds,
        isMyFavoriteEnabled,
        liveTicketAutoRefreshSeconds,
        nonLiveTicketAutoRefreshSeconds,
        isSendBehaviorEnabled,
        isScoreAlertEnabled,
        miniMyBetMaxDisplayCount,
        isShowTvChannelsTooltip,
        liveRefreshDuration,
        nonLiveRefreshDuration,
        oddsApiUrl,
        oddsApiWsUrl,
        isBetBuilderEnabled,
        betBuilderApiUrl,
        betBuilderWsUrl,
        getBetBuilderMinSelectionCount,
        placeBetDelayTime,
        liveScoreProviderConfig,
        getBehaviorMinutes,
        setGameScoreSportList,
        periodSportList,
        inningSportList,
        multipleSportsLeagueNames,
        sportsCupTournamentNames,
        sportsCup2TournamentNames,
        isShowMultipleSportsHotLink,
        isShowSportCupHotLink,
        isShowSportCup2HotLink,
        isEFootballZoneEnabled,
        isUseNewPlaceBetApi,
        mpTwoSubBetLeagueIds,
        isMpTwoSubBetEnabled,
        isJackpotEnabled,
        multipleSportsZoneSupportSports,
        isLiveStreamBanner1Enabled,
        isLiveStreamBanner2Enabled,
        liveStreamBannerEnableMatchIds,
        toggleAutoRefreshSeconds,
        isBetBuilderTutorialEnabled,
        betBuilderMinLegCount,
        isBetBuilderResultEnabled,
        defaultSportType,
        isFairExchangeEnabled,
        isIdleMonitorEnabled,
    };
};

type AllToggles = ReturnType<typeof _toggles>;

type AsiaTogglesWhitelist = keyof Pick<AllToggles,
| 'initToggle'
| 'registerAutoRefresh'
| 'isESportsImInSportEnabled'
| 'isGLiveStreamEnabled'
| 'isAvApiLiveStreamEnabled'
| 'gLiveStreamRestrictions'
| 'liveStreamRefreshSeconds'
| 'excludeSportList'
| 'defaultOpenMoreMarketInLiveLeagueIds'
| 'defaultOpenMoreMarketInLiveBySportsIds'
| 'isInLiveStreamWhiteList'
| 'supportFailedBetReasonSports'
| 'liveScoreRefreshSeconds'
| 'miniMyBetRefreshSeconds'
| 'miniMyBetWaitingRefreshSeconds'
| 'myBetRefreshSeconds'
| 'myBetWaitingRefreshSeconds'
| 'eFootballLeagueIds'
| 'isCasinoWidgetEnabled'
| 'isMiniGameWidgetEnabled'
| 'isVirtualFootballEnabled'
| 'isVirtualBasketballEnabled'
| 'isVoucherEnabled'
| 'isCashOutEnabled'
| 'cashOutValueRefreshSeconds'
| 'isMyFavoriteEnabled'
| 'liveTicketAutoRefreshSeconds'
| 'nonLiveTicketAutoRefreshSeconds'
| 'isSendBehaviorEnabled'
| 'isScoreAlertEnabled'
| 'miniMyBetMaxDisplayCount'
| 'getBehaviorMinutes'
| 'liveRefreshDuration'
| 'nonLiveRefreshDuration'
| 'oddsApiUrl'
| 'oddsApiWsUrl'
| 'isBetBuilderEnabled'
| 'betBuilderApiUrl'
| 'betBuilderWsUrl'
| 'getBetBuilderMinSelectionCount'
| 'placeBetDelayTime'
| 'liveScoreProviderConfig'
| 'setGameScoreSportList'
| 'periodSportList'
| 'inningSportList'
| 'multipleSportsLeagueNames'
| 'sportsCupTournamentNames'
| 'sportsCup2TournamentNames'
| 'isShowMultipleSportsHotLink'
| 'isShowSportCupHotLink'
| 'isShowSportCup2HotLink'
| 'isEFootballZoneEnabled'
| 'mpTwoSubBetLeagueIds'
| 'isMpTwoSubBetEnabled'
| 'isJackpotEnabled'
| 'multipleSportsZoneSupportSports'
| 'toggleAutoRefreshSeconds'
| 'isLiveStreamBanner1Enabled'
| 'isLiveStreamBanner2Enabled'
| 'liveStreamBannerEnableMatchIds'
| 'isBetBuilderTutorialEnabled'
| 'betBuilderMinLegCount'
| 'isBetBuilderResultEnabled'
| 'defaultSportType'
| 'isFairExchangeEnabled'
| 'isIdleMonitorEnabled'
>;

type EuroTogglesWhitelist = keyof Pick<AllToggles,
| 'initToggle'
| 'registerAutoRefresh'
| 'isESportsImInSportEnabled'
| 'isGLiveStreamEnabled'
| 'isAvApiLiveStreamEnabled'
| 'gLiveStreamRestrictions'
| 'liveStreamRefreshSeconds'
| 'excludeSportList'
| 'isInLiveStreamWhiteList'
| 'supportFailedBetReasonSports'
| 'myBetRefreshSeconds'
| 'myBetWaitingRefreshSeconds'
// | 'eFootballLeagueIds'
// | 'isCasinoWidgetEnabled'
| 'isMiniGameWidgetEnabled'
| 'isVirtualFootballEnabled'
| 'isVirtualBasketballEnabled'
| 'isVoucherEnabled'
| 'isCashOutEnabled'
| 'cashOutValueRefreshSeconds'
// | 'isMyFavoriteEnabled'
| 'liveTicketAutoRefreshSeconds'
| 'nonLiveTicketAutoRefreshSeconds'
| 'isSendBehaviorEnabled'
// | 'isScoreAlertEnabled'
| 'getBehaviorMinutes'
| 'liveRefreshDuration'
| 'nonLiveRefreshDuration'
| 'oddsApiUrl'
| 'oddsApiWsUrl'
| 'betBuilderApiUrl'
| 'betBuilderWsUrl'
| 'getBetBuilderMinSelectionCount'
| 'placeBetDelayTime'
| 'liveScoreProviderConfig'
| 'setGameScoreSportList'
| 'periodSportList'
| 'inningSportList'
| 'isUseNewPlaceBetApi'
| 'mpTwoSubBetLeagueIds'
| 'isMpTwoSubBetEnabled'
| 'isJackpotEnabled'
| 'toggleAutoRefreshSeconds'
| 'isLiveStreamBanner1Enabled'
| 'isLiveStreamBanner2Enabled'
| 'liveStreamBannerEnableMatchIds'
| 'isBetBuilderResultEnabled'
| 'defaultSportType'
| 'isFairExchangeEnabled'
| 'isIdleMonitorEnabled'
>;

type AsiaTogglesBlacklist = keyof Omit<AllToggles, AsiaTogglesWhitelist>;
type EuroTogglesBlacklist = keyof Omit<AllToggles, EuroTogglesWhitelist>;

const _useToggleStore = defineStore('Toggle', _toggles);

/** Store with all toggles. Should only use in places such as startup, shared components, or tests where choosing either {@link AsiaToggleStore} or {@link EuroToggleStore} is irrelevant. */
type ToggleStore = ReturnType<typeof _useToggleStore>;

/** Store with only whitelisted Asia site toggles. @see {@link AsiaTogglesWhitelist} */
type AsiaToggleStore = Omit<ToggleStore, AsiaTogglesBlacklist>; // use Omit blacklist instead of Pick whitelist to prevent breaking pinia store type

/** Store with only whitelisted Euro site toggles. @see {@link EuroTogglesWhitelist} */
type EuroToggleStore = Omit<ToggleStore, EuroTogglesBlacklist>; // use Omit blacklist instead of Pick whitelist to prevent breaking pinia store type

type IntersectToggleStore = Omit<ToggleStore, AsiaTogglesBlacklist | EuroTogglesBlacklist>;

/* eslint-disable padding-line-between-statements */
export function useToggleStore(siteStyle: SiteStyle.Asia): AsiaToggleStore
export function useToggleStore(siteStyle: SiteStyle.Euro): EuroToggleStore
export function useToggleStore(): IntersectToggleStore
export function useToggleStore(siteStyle?: SiteStyle) {
    const toggleStore = _useToggleStore();
    if (__ENV_DEV__) {
        if (siteStyle !== undefined && toggleStore._siteStyle !== SiteStyle.Unknown && siteStyle !== toggleStore._siteStyle) {
            console.warn(`useToggleStore: siteStyle mismatch. Expected ${toggleStore._siteStyle}, got ${siteStyle}`);
        }
    }

    return toggleStore;
}
/* eslint-enable padding-line-between-statements */
